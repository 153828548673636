import React, { useState } from "react";
import config from "./wizardConfig.json";

const AnalysisWizard = ({ setAnalysisStep }) => {
  const [questions, setQuestions] = useState([config.pipeline.options]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [_level, set_Level] = useState(0);
  const [def, setDef] = useState(false);
  const [defSelection, setDefselection] = useState(null);
  const [wStep, setWStep] = useState(0);
  const [selectedStepOptions, setSelectedStepOptions] = useState([]);

  const handleOptionClick = (option, level) => {
    set_Level(level);
    const newSelectedOptions = selectedOptions.slice(0, level);
    newSelectedOptions.push(option);
    setSelectedOptions(newSelectedOptions);

    if (option.pipeline) {
      setSelectedPipeline(option.pipeline);
    } else if (option.options || option.next) {
      const newQuestions = questions.slice(0, level + 1);
      newQuestions.push(option.options || option.next.options);
      setQuestions(newQuestions);
      console.log(newQuestions);
    }
  };

  const next = () => {
    console.log(selectedPipeline, defSelection);

    if (
      _level > 1 &&
      !["deseq2", "edger", "limma"].includes(selectedPipeline)
    ) {
      setDef(true);
    }

    if (
      defSelection ||
      ["deseq2", "edger", "limma"].some((v) => selectedPipeline.includes(v))
    ) {
      setAnalysisStep(2);
    }
  };

  console.log(config.pipeline.options[0].options[1].next.options);

  return (
    <>
      <div
        className="div-block-108"
        style={{
          display: wStep < 3 ? "flex" : "none",
        }}
      >
        {wStep === 0 && (
          <>
            <div className="text-block-57">Analysis Wizard</div>
            <div className="text-block-60">
              Please designate research methodology and research aim
            </div>
            <div className="div-block-109">
              <div className="div-block-110">
                <div className="text-block-58">
                  I want to discover variants/polymorphisms on a organism
                  genome/ exome
                </div>
                <div className="text-block-59">Whole genome/exome analysis</div>
              </div>
              <div
                className="div-block-110"
                onClick={() => setSelectedStepOptions(["rna-sequencing"])}
              >
                <div className="text-block-58">
                  I want to analysis expression of genes
                </div>
                <div
                  className="text-block-59"
                  style={{
                    backgroundColor: selectedStepOptions.includes(
                      "rna-sequencing"
                    )
                      ? "#4f70ff"
                      : "",
                    color: selectedStepOptions.includes("rna-sequencing")
                      ? "#fff"
                      : "",
                  }}
                >
                  RNA Sequencing
                </div>
              </div>
              <div className="div-block-110">
                <div className="text-block-58">
                  I want to compare the expression of genes based on the
                  proteome profile of different experimental groups
                </div>
                <div className="text-block-59">Proteomic Analysis</div>
              </div>
              <div className="div-block-110">
                <div className="text-block-58">
                  I want a Epigenetic pattern among the genome
                </div>
                <div className="text-block-59">Epigenetics Analysis</div>
              </div>
            </div>
            <div className="text-block-60">
              Please designate the research field
            </div>
            <div className="div-block-109 part-2">
              <div
                className="text-block-59"
                style={{
                  backgroundColor: selectedStepOptions.includes("0")
                    ? "#4f70ff"
                    : "",
                  color: selectedStepOptions.includes("0") ? "#fff" : "",
                }}
                onClick={() =>
                  setSelectedStepOptions([...selectedStepOptions, "0"])
                }
              >
                Medical Research
              </div>
              <div
                className="text-block-59"
                style={{
                  backgroundColor: selectedStepOptions.includes("1")
                    ? "#4f70ff"
                    : "",
                  color: selectedStepOptions.includes("1") ? "#fff" : "",
                }}
                onClick={() =>
                  setSelectedStepOptions([...selectedStepOptions, "1"])
                }
              >
                Veterinary Science
              </div>
              <div
                className="text-block-59"
                onClick={() =>
                  setSelectedStepOptions([...selectedStepOptions, "2"])
                }
                style={{
                  backgroundColor: selectedStepOptions.includes("2")
                    ? "#4f70ff"
                    : "",
                  color: selectedStepOptions.includes("2") ? "#fff" : "",
                }}
              >
                Agriculture
              </div>
              <div
                className="text-block-59"
                style={{
                  backgroundColor: selectedStepOptions.includes("3")
                    ? "#4f70ff"
                    : "",
                  color: selectedStepOptions.includes("3") ? "#fff" : "",
                }}
                onClick={() =>
                  setSelectedStepOptions([...selectedStepOptions, "3"])
                }
              >
                Other Biological Species
              </div>
            </div>
            <div className="analysis-start-btn" onClick={() => setWStep(1)}>
              {" "}
              Next{" "}
            </div>
          </>
        )}

        {wStep === 1 && (
          <>
            <div className="text-block-60">RNA Sequencing</div>
            <div className="div-block-109">
              <div
                className="div-block-110"
                onClick={() =>
                  setSelectedStepOptions([
                    ...selectedStepOptions,
                    "bulk-rna-sequencing",
                  ])
                }
              >
                <div className="text-block-58">
                  I want to compare the expression of genes with different
                  experimental groups, at tissue level
                </div>
                <div
                  className="text-block-59"
                  style={{
                    backgroundColor: selectedStepOptions.includes(
                      "bulk-rna-sequencing"
                    )
                      ? "#4f70ff"
                      : "",
                    color: selectedStepOptions.includes("bulk-rna-sequencing")
                      ? "#fff"
                      : "",
                  }}
                >
                  Bulk RNA sequencing
                </div>
              </div>
              <div
                className="div-block-110"
                // onClick={() =>
                //   setSelectedStepOptions([
                //     ...selectedStepOptions,
                //     "single-cell-rna-sequencing",
                //   ])
                // }
              >
                <div className="text-block-58">
                  I want to analysis expression of genes based on the
                  transcriptome profile at single-cell level
                </div>
                <div
                  className="text-block-59"
                  style={{
                    backgroundColor: selectedStepOptions.includes(
                      "single-cell-rna-sequencing"
                    )
                      ? "#4f70ff"
                      : "",
                    color: selectedStepOptions.includes(
                      "single-cell-rna-sequencing"
                    )
                      ? "#fff"
                      : "",
                  }}
                >
                  Single-cell RNA Sequencing
                </div>
              </div>
            </div>
            <div className="analysis-start-btn" onClick={() => setWStep(2)}>
              {" "}
              Next{" "}
            </div>
          </>
        )}

        {wStep === 2 && (
          <>
            <div className="text-block-60">Bulk RNA sequencing</div>
            <div className="div-block-109">
              <div className="div-block-110"
                onClick={() => setSelectedStepOptions([...selectedOptions, "deseq2"])}
              >
                <div className="text-block-58">
                  Assessing differential gene expression between two or more
                  groups
                </div>
                <div className="text-block-59" 
                  style={{
                    backgroundColor: selectedStepOptions.includes("deseq2")
                      ? "#4f70ff"
                      : "",
                    color: selectedStepOptions.includes("deseq2")
                      ? "#fff"
                      : "",
                  }}
                >
                  RNA-Seq + Differential abundance pipelines
                </div>
              </div>
              <div className="div-block-110">
                <div className="text-block-58">
                  Performing assembly and annotation of mRNAs from a non-model
                  organism
                </div>
                <div className="text-block-59">
                  De nova transript assembly pipeline
                </div>
              </div>
              <div className="div-block-110">
                <div className="text-block-58">
                  Detect gene fusion using transcripts
                </div>
                <div className="text-block-59">RNA fusion pipeline</div>
              </div>
              <div className="div-block-110">
                <div className="text-block-58">
                  Evolution of samples microbiome through transcripts
                </div>
                <div className="text-block-59">Metatranscriptomics</div>
              </div>
            </div>
            <div className="analysis-start-btn" onClick={() => setWStep(3)}>
              {" "}
              Next{" "}
            </div>
          </>
        )}
      </div>

      {wStep === 3 && (
        <div className="div-block-107">
          <h3>Analysis Wizard</h3>
          {def ? <h4>Differential Expression</h4> : ""}
          <br />
          {!def &&
            questions.map((questionOptions, level) => (
              <div key={level} className="question-box">
                {questionOptions.map((option, index) => (
                  <div
                    key={index}
                    className="_w-option-1"
                    style={
                      selectedOptions[level] === option
                        ? {
                            backgroundColor: "#a5afe033",
                            border: "1px solid #4d91ff",
                          }
                        : {}
                    }
                    onClick={() => handleOptionClick(option, level)}
                  >
                    <div className="text-block-56">{option.label}</div>
                  </div>
                ))}
              </div>
            ))}
          {def && (
            <>
              <br />
              <br />
              <div className="question-box">
                <div
                  className="_w-option-1"
                  style={
                    defSelection === "deseq2"
                      ? {
                          backgroundColor: "#a5afe033",
                          border: "1px solid #4d91ff",
                        }
                      : {}
                  }
                  onClick={() => setDefselection("deseq2")}
                >
                  <div className="text-block-56">
                    I want to use default pipeline for bulk RNA sequencing
                  </div>
                </div>
                <div
                  className="_w-option-1"
                  style={
                    defSelection &&
                    ["edger, limma"].some((v) => defSelection.includes(v))
                      ? {
                          backgroundColor: "#a5afe033",
                          border: "1px solid #4d91ff",
                        }
                      : {}
                  }
                  onClick={() => setDefselection("edger/limma")}
                >
                  <div className="text-block-56">
                    I want to specific RNA differential analysis method
                    *contrasting biological groups composed of more than 12
                    samples tend to present a high overlap of differentially
                    expressed genes identified among the three tools*
                  </div>
                </div>
              </div>

              {defSelection !== "deseq2" && defSelection && (
                <div className="question-box">
                  <div
                    className="_w-option-1"
                    style={
                      defSelection === "edger"
                        ? {
                            backgroundColor: "#a5afe033",
                            border: "1px solid #4d91ff",
                          }
                        : {}
                    }
                    onClick={() => setDefselection("edger")}
                  >
                    <div className="text-block-56">EdgeR</div>
                  </div>
                  <div
                    className="_w-option-1"
                    style={
                      defSelection === "limma"
                        ? {
                            backgroundColor: "#a5afe033",
                            border: "1px solid #4d91ff",
                          }
                        : {}
                    }
                    onClick={() => setDefselection("limma")}
                  >
                    <div className="text-block-56">Limma</div>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="analysis-start-btn" onClick={() => next()}>
            {" "}
            Next{" "}
          </div>
        </div>
      )}
    </>
  );
};

export default AnalysisWizard;
