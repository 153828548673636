import React, { useEffect, useRef, useState } from "react";
import api from "../api/projects";
import { useLocation } from "react-router-dom";

const RocketChat = () => {
  const [projectId, setProjectId] = useState(null);
  const [rocketChatURL, setRocketChatURL] = useState('http://34.143.241.247:3000');
  const location = useLocation();


  // useEffect(() => {
  //   if (location) {
  //     console.log(location);
  //     let projectId = location?.pathname.split("/")[1];
  //     setProjectId(projectId);
  //   }

  //   async function getProjectData() {
  //     const project_data = await api.get(`/projects/${projectId}`);
  //     if (project_data) {
  //       setRocketChatURL(`http://34.143.241.247:3000/channel/${project_data.data?.name}`)
  //     }
  //   }
  //   getProjectData();
  // }, [location]);

  // // Send the login token to the Rocket.Chat iframe after it loads
  // useEffect(() => {
  //   const fetchUserToken = async () => {
  //     try {
  //       const response = await api.get(`/loginRocketchatUser`);
  //       const userToken = response.data;
  //       const iframe = document.getElementById("rocketchat-iframe");
  //       if (iframe) {
  //         iframe.contentWindow.postMessage(
  //           {
  //             event: "login-with-token",
  //             loginToken: userToken || null,
  //           },
  //           rocketChatURL
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user token:", error);
  //     }
  //   };

  //   fetchUserToken();
  // }, []);

  return (
    <div
      style={{
        // round div floating bottom right corner
        position: "fixed",
        width: "80vw",
        height: "75vh",
        bottom: "9%",
        right: "7%",
        zIndex: "9999",
        borderRadius: "6px",
        overflow: "hidden",
        boxShadow: "0 0 10px rgba(0,0,0,0.5)",
      }}
    >
      {/* <iframe
        id="rocketchat-iframe"
        title="RocketChat"
        src={rocketChatURL}
        width="100%"
        height="100%" // Adjust size as needed
        style={{ border: "none" }}
        onLoad={() => console.log("Iframe loaded")} // Added for debugging
      /> */}
    </div>
  );
};

export default RocketChat;
