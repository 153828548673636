import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import workflows from "./workflows.json";

const Strategy = ({
  setStrategyOut,
  workflow,
  selectedRootWorkflow,
  setSelectedWorkflow,
}) => {
  const [showAlignmentModal, setShowAlignmentModal] = useState(false);

  const [programOptions, setProgramOptions] = useState([
    {
      value: "star",
      label: "Star",
    },
    {
      value: "kallisto",
      label: "Kallisto",
    },
    {
      value: "salmon",
      label: "Salmon",
    }
  ]);
  const [selectedProgram, setSelectedProgram] = useState({
    value: "star",
    label: "Star",
  });
  const [selectedDiff, setSelectedDiff] = useState({
    value: "deseq2",
    label: "DESeq2",
  });

  const handleCloseAlignmentModal = () => {
    setShowAlignmentModal(false);
  };

  useEffect(() => {
    // get single workflow object from workflow, if name includes "star" & "alignment"
    const _selectedWorkflow = workflows.filter(
      (_w) =>
        _w.name.includes(selectedProgram.value) &&
        _w.name.includes(selectedRootWorkflow?.rootWorkFlow.split("rnaseq_")[1])
    )[0];
    console.log("selected workflow", _selectedWorkflow);
    if (_selectedWorkflow) {
      setSelectedWorkflow(_selectedWorkflow);
    }
  }, [selectedProgram]);

  console.log(selectedRootWorkflow, "selectedRootWorkflow");

  return (
    <>
      {/* <div
        className="div-block-step"
        onClick={() => setShowAlignmentModal(true)}
      >
        <div className="text-block-49">Alignment</div>
        <div className="text-block-step option">Star</div>
        <div className="div-block-num">1</div>
      </div> */}
      <div className="text-block-50">
        {selectedRootWorkflow && selectedRootWorkflow.steps[0].description}
      </div>

      {workflow &&
        workflow.analysisSteps?.map((step, index) => {
          const stepOptions = step?.options?.map((item) => ({
            value: item.toLowerCase(),
            label: item.charAt(0).toUpperCase() + item.slice(1),
          }));

          return (
            <div
              className="div-block-step"
              key={index}
              // onClick={() =>  setShowAlignmentModal(true)}
            >
              <div className="text-block-49">{step.name}</div>
      
              <div className={"text-block-step option"}>
                <Select
                  defaultValue={
                    step.name === "Differential Expression"
                      ? { value: "deseq2", label: "DESeq2" }
                      : selectedProgram
                  }
                  onChange={
                    step.name === "Differential Expression"
                      ? setSelectedDiff
                      : setSelectedProgram
                  }
                  options={step.name === "Differential Expression" ? [{ value: "deseq2", label: "DESeq2" }] : ( step.name === "Alignment / Merged Count" ? [
                    {
                      value: "star",
                      label: "Star",
                    },
                    {
                      value: "kallisto",
                      label: "Kallisto",
                      disabled: true

                    },
                    {
                      value: "salmon",
                      label: "Salmon",
                      disabled: true
                    }
                  ] : programOptions)}
                  // className={"reference-dropdown"}
                  styles={{
                    width: "100%",
                  }}
                  disabled={step.name === "Differential Expression"}
                  isOptionDisabled={(option) => option.disabled}
                  // placeholder="Star"
                  menuPosition="fixed"
                  menuPlacement="auto"
                />
              </div>
              {/* <div className="div-block-num">{index + 1}</div>  */}
            </div>
          );
        })}

      {/* <div className="div-block-step">
        <div className="text-block-49">Add Step</div>
        <div className="text-block-step option">Add Step</div>
        <div className="div-block-num">Add Step</div>
      </div> */}

      <Modal
        show={showAlignmentModal}
        onHide={handleCloseAlignmentModal}
        // backdrop="static"
        keyboard={true}
        // fullscreen={true}
        centered
        // size="lg"
        dialogClassName="div-block-12"
        // contentClassName="div-block-12"
      >
        {/* <Modal.Body> */}
        {/* <div className="div-block-12" style={{
          width: "100%"
        }}> */}
        <div className="div-block-13">
          <div
            className="div-block-25"
            onClick={() => setShowAlignmentModal(false)}
          >
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60fc3a2f21621b84f7932381_icons8-back-96.png"
              loading="lazy"
              width={16}
              alt=""
            />
            <div className="text-block-3">Back</div>
          </div>
          <div className="text-block-2">Alignment</div>
          <div />
        </div>
        <div className="div-block-27">
          <div className="div-block-39">Star</div>
          <div className="div-block-39">Bowtie2</div>
          <div className="div-block-39">Burrows-Wheeler Aligner (BWA)</div>
          <div className="div-block-39">HiSat2</div>
          <div className="div-block-39">Tophat</div>
        </div>
        <div className="div-block-29">
          <div className="div-block-30">
            <div className="div-block-43">
              <div className="text-block-18">Star:</div>
              <div className="btn-2">
                <div className="btn-2-text">Version 2.0</div>
                <img
                  src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60fc2f152b2dc0e3c9a69611_icons8-chevron-down-90.png"
                  loading="lazy"
                  width={22}
                  alt=""
                  className="image"
                />
              </div>
            </div>
            <div className="text-block-17">Application:</div>
            <p className="text-block-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
            <div className="text-block-17">Use Case:</div>
            <p className="text-block-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
            <div className="text-block-17">Input Requirments</div>
            <p className="text-block-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
          </div>
          <div className="div-block-31">
            <div className="text-block-17 center">App Information</div>
            <p className="text-block-8 center">
              <a href="#" className="link">
                Homepage
              </a>
              <br />
              <br />
              <a href="#" className="link">
                Publication
                <br />
              </a>
              <br />
              <a href="#" className="link">
                Source Code
              </a>
              <br />
              <br />
              <a href="#" className="link">
                License
              </a>
            </p>
          </div>
        </div>
        <div className="div-block-26">
          <div className="div-block-40">
            <div className="div-block-41">Inputs</div>
            <div className="div-block-41">Settings</div>
            <div className="div-block-41">Outputs</div>
          </div>
          <div className="div-block-42" />
        </div>
        {/* </div>/ */}
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
};

export default Strategy;
