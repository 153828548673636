import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import api from "../../../api/projects";
import { useForm } from "react-hook-form";
import Nav from "../../header/Nav.component";
import { Modal, Button } from "react-bootstrap";
import { listProjectFiles, analysisCreate } from "../../../actions/projects";
import moment from "moment";
import Strategy from "./Strategy";
import InputFiles from "./InputFiles";
import { v4 as uuidv4 } from "uuid";
import "../Analysis.css";
import ReferenceFile from "./ReferenceFile";
import ExperimentalDesign from "./ExperimentalDesign";
import ComparisonSelection from "./ComparisonSelection";
import Summary from "./Summary";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import AnalysisWizard from "../analysisWizard/analysisWizard";

import workflows_data from "./workflows.json";
import rootWorkflows from "./rootworkflows.json";
// import history from '../../history';
const user = "6054074f8c41cc4cba4333ef";

const Analysis = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const projectFiles = useSelector((state) => state.projects.projectFiles);
  const currentUser = useSelector((state) => state.projects.currentUser);
  // get project id
  // const match = useParams();

  const [plf, setPfs] = useState(null);

  useEffect(() => {
    dispatch(listProjectFiles(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    let combineFilesList = [];
    projectFiles?.files.forEach((file) => {
      if (file.file_type.includes("fastq")) {
        let newFileName = file.file_name.split(".fastq")[0];

        if (newFileName.slice(-1) === "1") {
          newFileName += "2";
          combineFilesList.push({
            file_name: newFileName,
            imaginary_file_type: file.imaginary_file_type,
            tags: file.tags,
            file_tags: file.file_tags,
            file_color: file.file_color,
          });
        }
      }
    });
    setPfs(projectFiles?.files);
  }, [projectFiles]);

  useEffect(() => {
    dispatch(listProjectFiles);
  }, []);

  const [showAlignmentModal, setShowAlignmentModal] = useState(false);
  const [openExpDesignModal, setOpenExpDesignModal] = useState(false);
  const [analysisStep, setAnalysisStep] = useState(0);
  const [expDesign, setExpDesign] = useState([]);
  const [steps, setSteps] = useState([
    { name: "Analysis Strategy" },
    { name: "Input Files" },
    { name: "Reference File" },
    { name: "Experimental Design" },
    { name: "Comparison Selection" },
    { name: "Summary" },
  ]);

  const [strategyOut, setStrategyOut] = useState(undefined);
  const [inputFileOut, setInputFileOut] = useState(undefined);
  const [referenceFileOut, setReferenceFileOut] = useState(undefined);
  const [expDesignOut, setExpDesignOut] = useState(undefined);
  const [comparisonSelectionOut, setComparisonSelectionOut] =
    useState(undefined);
  const [vmSpeed, setVmSpeed] = useState({
    value: "high",
    label: "High",
  });

  const [workflows, setWorkflows] = useState(workflows_data);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [selectedRootWorkflow, setSelectedRootWorkflow] = useState(null);

  const [selectedStep, setSelectedStep] = useState(0);

  const [analysisSteps, setAnalysisSteps] = useState([
    { name: "Alignment+quantification - Star", buttonName: "Configuration" },
    { name: "Differential Expression", buttonName: "Settings" },
  ]);

  const [showInputsModal, setShowInputsModal] = useState(false);

  const [metaDataFile, setMetaDataFile] = useState(null);

  const inputColumns = [
    {
      name: "Name",
      selector: "file_name",
      sortable: true,
      format: ({ file_name, file_color }) => (
        <span
          className="file"
          style={{
            "--color": `#${file_color}`,
            "--hover-color": file_color === "ffffff" ? "" : `#${file_color}`,
            "--margin": file_color === "ffffff" ? "10px" : `5px`,
          }}
        >
          {file_name}
        </span>
      ),
    },
    {
      name: "Tags",
      selector: "tags",
      format: ({ tags }) => (
        <span style={{ margin: "5px" }}>
          {tags.map((tag, index) => (
            <span className="tag" key={index}>
              {tag}
            </span>
          ))}
        </span>
      ),
      wrap: true,
    },
    {
      name: "Created",
      selector: "date_created",
      sortable: true,
      format: (row) => (
        <span>
          {moment.unix(row.date_created).format("MM/DD/YYYY")},&nbsp; (
          {moment(
            moment.unix(row.date_created).format("MMDDYYYY"),
            "MMDDYYYY"
          ).fromNow()}
          )
        </span>
      ),
    },
    {
      name: "Type",
      selector: "file_type",
      sortable: true,
    },
    {
      name: "Size",
      selector: "file_size",
      sortable: true,
    },
  ];

  const handleCloseAlignmentModal = () => {
    setShowAlignmentModal(false);
  };

  const handleSelectWorkflow = () => {
    setAnalysisStep(2);
  };

  let rnaseq_star_align_inputs = {
    "rnaseq_star_alignment.gtf":
      "gs://reference-genome-sequences/GRCh38/gencode.v38.basic.annotation.gtf",
    "rnaseq_star_alignment.read_one_fastqs": [
      "gs://reference-genome-sequences/samples/subsample_115_1.fastq.gz",
      "gs://reference-genome-sequences/samples/subsample_157_1.fastq.gz",
      "gs://reference-genome-sequences/samples/subsample_168_1.fastq.gz",
    ],
    "rnaseq_star_alignment.read_two_fastqs": [
      "gs://reference-genome-sequences/samples/subsample_115_2.fastq.gz",
      "gs://reference-genome-sequences/samples/subsample_157_2.fastq.gz",
      "gs://reference-genome-sequences/samples/subsample_168_2.fastq.gz",
    ],
    "rnaseq_star_alignment.stardb_tar_gz":
      "gs://reference-genome-sequences/GRCh38/STARDB.tar.gz",
  };

  const [nData, setNData] = useState([]);

  const changeFileHandler = (event) => {
    let _file = event.target.files[0];
    setMetaDataFile(_file);

    const reader = new FileReader();
    reader.onload = function (evt) {
      // console.log(evt.target.result);
      let g = evt.target.result.split("\n").map((line) => line.split("\t"));
      setExpDesign(g[0].splice(1));
    };
    reader.readAsText(_file);
  };

  // useEffect(() => {
  //   const reader = new FileReader();
  //   reader.onload = function(evt) {
  //     console.log(evt.target.result);
  //   };
  //   reader.readAsText(metaDataFile);
  // }, [metaDataFile])

  useEffect(() => {
    function getRandomArbitrary(min, max) {
      return Math.random() * (max - min) + min;
    }

    let _ndata = [];

    for (var i = 0; i < 30; i++) {
      _ndata.push({
        name: `subsample_${getRandomArbitrary(20, 500)}_1_2`,
        id: i,
        size: `${getRandomArbitrary(3, 5)} mb`,
        type: `FastQC`,
        date: "26-09-2021",
      });
    }

    setNData(_ndata);
  }, []);

  const [project, setProject] = useState(undefined);
  const params = useParams();

  // useEffect(() => {
  //   async function fetchData() {
  //     const project_data = await api.get(`/projects/${params.id}`);
  //     if (project_data) {
  //       setProject(project_data.data);
  //     }
  //   }
  //   fetchData();
  // }, []);

  const uploadFile = async (file, user_bucket, filePath) => {
    console.log("Upload function", filePath, file);
    let inputformData = new FormData();
    inputformData.append("file", file);
    inputformData.append("bucketName", user_bucket);
    inputformData.append("filePath", filePath);

    var config = {
      method: "post",
      // url: "http://localhost:8080/upload",
      url: "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/upload",
      data: inputformData,
    };

    try {
      const response = await axios(config);
      console.log(response);
      console.log("File uploaded done", user_bucket, filePath, file);
    } catch (error) {
      console.log(error);
    }
  };

  // -------------- START ANALYSIS --------------
  const startAnalysis = async () => {
    console.log("start analysis");

    let run_id = uuidv4();

    // Go to output page
    history.push(`/${params.id}/output`);
    console.log(
      selectedWorkflow.name,
      inputFileOut,
      referenceFileOut,
      expDesignOut,
      comparisonSelectionOut
    );

    let speeds = {
      slow: {
        cpu: 8,
        mem: 16,
      },
      medium: {
        cpu: 16,
        mem: 32,
      },
      fast: {
        cpu: 32,
        mem: 64,
      },
    };

    // generate input_json by combining data from workflow.json "inputs" & getting bucket_name/file_name from inputFileOut
    let input_json = {};
    selectedWorkflow.inputs.map(async (input) => {
      console.log(input);
      let param_files = [];
      if (input?.name?.includes("read_one_fastqs")) {
        inputFileOut?.map((file) => {
          if (file?.read_num === "1") {
            param_files.push(
              `gs://${file?.bucket_id}/uploads/${file?.file_path}`
            );
          }
        });
        param_files = param_files.sort();
      } else if (input?.name?.includes("read_two_fastqs")) {
        inputFileOut?.map((file) => {
          if (file?.read_num === "2") {
            param_files.push(
              `gs://${file?.bucket_id}/uploads/${file?.file_path}`
            );
          }
        });
        param_files = param_files.sort();
      } else if (input?.name?.includes("gtf")) {
        param_files =
          // "gs://reference-genome-sequences/GRCh38/gencode.v38.basic.annotation.gtf";
          // param_files = "gs://reference-genome-sequences/GRCh38/chr22/gencode_human_chr22.gtf.gz";
          param_files = referenceFileOut.gtf.value;
      } else if (input?.name?.includes("reference_fasta")) {
        // param_files = "gs://reference-genome-sequences/GRCh38/chr22/STARDB.tar.gz";
        // param_files = "gs://reference-genome-sequences/GRCh38/STARDB.tar.gz";
        param_files = referenceFileOut.fasta.value;
      } else if (input?.name?.includes("reference_idx")) {
        // param_files = "gs://reference-genome-sequences/GRCh38/chr22/STARDB.tar.gz";
        // param_files = "gs://reference-genome-sequences/GRCh38/STARDB.tar.gz";
        param_files = referenceFileOut.idx.value;
      } else if (input?.name?.includes("metadata")) {
        // if expDesignOut is a string
        if (!expDesignOut.includes("sample_row\t")) {
          param_files = expDesignOut;
        } else {
          console.log("Uploading metadata file");
          // upload metadata (expDesignOut json) to user bucket
          const user_bucket = `skygenic_user_${currentUser.id}_standard_1`;
          let filePath = `analysis_uploads/${params.id}/${run_id}`;

          console.log(JSON.stringify(expDesignOut));
          const metadata_blob = new Blob([expDesignOut], {
            type: "application/txt",
          });
          const metadata_file = new File([metadata_blob], "metadata.txt", {
            type: "application/txt",
          });

          param_files = `gs://${user_bucket}/${filePath}/metadata.txt`;
          await uploadFile(metadata_file, user_bucket, filePath);
        }
      } else if (input?.name?.includes("comparisons")) {
        param_files = comparisonSelectionOut;
      } else if (input?.name?.includes("count_table")) {
        param_files = `gs://${inputFileOut[0]?.bucket_id}/uploads/${inputFileOut[0]?.file_path}`;
      } else if (input?.name?.includes("map_qc_samples")) {
        param_files = {};
        inputFileOut?.map((file) => {
          param_files[file.file_name] = 1;
        });
      } else if (input.name.includes("ncpu")) {
        console.log(vmSpeed.value, speeds[vmSpeed.value].cpu)
        param_files = speeds[vmSpeed.value].cpu;
      } else if (input.name.includes("memory_gb")) {
        console.log(vmSpeed.value, speeds[vmSpeed.value].mem)
        param_files = speeds[vmSpeed.value].mem;
      }

      input_json[input.name] = param_files;
    });

    // input_json['rnaseq_kallisto_db_build.kallisto_db_build.memory_gb'] = 8
    // input_json['rnaseq_kallisto_db_build.kallisto_db_build.ncpu'] = 4

   
    input_json = {
      "rnaseq_full_workflow.stardb_tar_gz": "gs://skygenic-references/iara_test/STARDB.tar.gz",
      "rnaseq_full_workflow.comparisons": ["group"],
      "rnaseq_full_workflow.read_one_fastqs": [
        "gs://skygenic-references/iara_test/SRR13329769_1.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329770_1.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329771_1.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329772_1.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329773_1.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329774_1.fastq.gz"
    ],
      "rnaseq_full_workflow.read_two_fastqs": [
        "gs://skygenic-references/iara_test/SRR13329769_2.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329770_2.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329771_2.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329772_2.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329773_2.fastq.gz",
        "gs://skygenic-references/iara_test/SRR13329774_2.fastq.gz"
    ],
      "rnaseq_full_workflow.gtf": "gs://skygenic-references/iara_test/gtf_chr19.gtf.gz",
      "rnaseq_full_workflow.metadata": "gs://skygenic-references/iara_test/metadata.txt"
    }


    console.log("INPUT-JSONN", input_json);


    
    var input_blob = new Blob([JSON.stringify(input_json)], {
      type: "application/json",
    });
    var input_file = new File([input_blob], "input.json", {
      type: "application/json",
    });

    const user_bucket = `skygenic_user_${currentUser.id}_standard_1`;
    let filePath = `analysis_uploads/${params.id}/${run_id}`;

    let g1g = await uploadFile(input_file, user_bucket, filePath);
    console.log(g1g);

    const configData = {
      // inputBucketName: 'skygenic_user_647755594ef07a000cb74fe5_standard_1',
      inputsBucketName: user_bucket,
      // inputsBucketFileName: `analysis_uploads/64e44fb9ae359a117e5a915a/input.json`,
      inputsBucketFileName: `analysis_uploads/${params.id}/${run_id}/input.json`,
      destinationBucketName: user_bucket,
      destinationBucketFileName: `${user_bucket}/analysis-runs/${params.id}/outputs`,
      wdlFileName: selectedWorkflow.wdlFileName,
      projectId: params.id,
      run_id: run_id,
    };

    var config2 = {
      method: "post",
      url: "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/startanalysis",
      // url: "http://localhost:8080/startanalysis",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(configData),
    };

    console.log("----------------", configData);
    console.log(config2);


    dispatch(
      analysisCreate(params.id, {
        // user_id: "6054074f8c41cc4",
        run_id: run_id,
        input: JSON.stringify(input_json),
        status: "Initiating",
        project_id: params.id,
        pipelineDetails: configData,
      })
    );
    toast.success(
      "Analysis has initiated, Please wait couple minutes for the analysis status",
      { autoClose: 8000 }
    );

    try {
      const response = await axios(config2);
      toast.success(
        "Analysis has successful started running.",
        { autoClose: 8000 }
      );

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // url: "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/startanalysis",

  // const input_json = {
  //   "rnaseq_deseq2_analysis.comparisons": comparisonSelectionOut,
  //   "rnaseq_deseq2_analysis.gtf_annotation": referenceFileOut.value,
  //   "rnaseq_deseq2_analysis.count_table": `gs://${inputFileOut[0][0].bucket_id}/uploads/${inputFileOut[0][0].file_path}`,
  //   "rnaseq_deseq2_analysis.metadata":
  //     "gs://analysis_user_sgtest1/testuniqueid123_metadata.tsv",
  // };

  //   const input_json = {
  //     "rnaseq_star_alignment.gtf": "gs://reference-genome-sequences/GRCh38/chr22/gencode_human_chr22.gtf.gz",
  //     "rnaseq_star_alignment.read_one_fastqs": [
  //        "gs://reference-genome-sequences/samples/subsample_115_1.fastq.gz",
  //        "gs://reference-genome-sequences/samples/subsample_157_1.fastq.gz",
  //        "gs://reference-genome-sequences/samples/subsample_168_1.fastq.gz"
  //     ],
  //     "rnaseq_star_alignment.read_two_fastqs": [
  //        "gs://reference-genome-sequences/samples/subsample_115_2.fastq.gz",
  //        "gs://reference-genome-sequences/samples/subsample_157_2.fastq.gz",
  //        "gs://reference-genome-sequences/samples/subsample_168_2.fastq.gz"
  //     ],
  //     "rnaseq_star_alignment.stardb_tar_gz": "gs://reference-genome-sequences/GRCh38/chr22/STARDB.tar.gz",
  //  }


  useEffect(() => {
    if (selectedWorkflow !== null) {
      setSteps(selectedWorkflow.steps);
    }
  }, [selectedWorkflow]);

  
  let g1 = {
    "rootWorkFlow": "rnaseq_full",
    "displayName": "RNA-Seq Full",
    "defaultLinkedWorkFlow": "rnaseq_full_workflow",
    "description": "RNA-Seq raw data to differential gene expression",
    "steps": [
        {
            "name": "Analysis Strategy",
            "description": "This workflow is for taking raw experiment data and performing differential gene expression on it. Dataset quality control and data preparation will automatically occur as part of this workflow. Select the desired analysis approach and program."
        },
        {
            "name": "Input Files",
            "description": "The input files are .FASTA, .FASTQ, or unaligned .bam files. Click on the table to select the files made available to the project.  If a file is denoted as xxx.R1 and xxx.R2 or some variation than the unaligned files are paired end (the most common) and will automatically be displayed as xxx.R12 for simplication. If the desired file isn't available than it needs to be shared with the project by it's owner in the Files tab.",
            "programs": {
                "star": "Star program generates genome level expression. It is more comprehensive but also requires longer computational time and associated cost.",
                "kallisto": "Kallisto program generates transcriptome level expression.",
                "salmon": "Salmon program generates transcriptome level expression."
            }
        },
        {
            "name": "Reference File",
            "programs": {
                "star": "Two reference files are required. The gene annotation file .gtf which is available from a number of public repositories. The most common annotation files are pre-loaded for use. The second input is a genome index data base file which we recommend be generated by the user specific to their genome/annotation combination. It only needs to be generated once. Select this file from the project files in the format of tar.gz. If none of these files are available within the storage selection use the 'RNA-Seq STAR DB Build' workflow to generate the genome index file prior to proceeding.",
                "kallisto": "Two reference input is a genome index data base file which we recommend be generated by the user specific to their genome/annotation combination. It only needs to be generated once. Select this file from the project files in the format of idx.tar.gz. If none of these files are available within the storage selection use the 'RNA-Seq DB Build' workflow to generate the genome index file prior to proceeding.",
                "salmon": "Two reference input is a genome index data base file which we recommend be generated by the user specific to their genome/annotation combination. It only needs to be generated once. Select this file from the project files in the format of idx.tar.gz. If none of these files are available within the storage selection use the 'RNA-Seq DB Build' workflow to generate the genome index file prior to proceeding."
            }
        },
        {
            "name": "Experimental Design",
            "description": "For differential gene expression analysis the experimental design identifying the conditions and levels must be generated.  It can be uploaded in .tsv format per the example pop up or generated within Skygenic by selecting the experiment design generator. "
        },
        {
            "name": "Summary",
            "description": "Please review the selected analysis strategy and associated components. Select the desired computational speed and additional computing settings.  The results from this analysis run are available within the Output tab."
        }
    ]
}

  return (
    <>
      <Nav active={3} />
      <ToastContainer />
      <div
        className="div-block-107"
        style={{
          display: analysisStep === 0 ? "flex" : "none",
        }}
      >
        <div
          className="div-block-64"
          onClick={() => {
            setAnalysisStep(1);
            setSelectedRootWorkflow(g1);
            setSelectedWorkflow(
              workflows.filter(
                (_wf) => _wf.name === g1.defaultLinkedWorkFlow
              )[0]
            );
          }}
        >
          <img
            src="https://assets-global.website-files.com/60faa77e21b22054e04713b8/61b86eff3613d1659d0acc31_icons8-workflow-96.png"
            loading="lazy"
            width={42}
            alt=""
          />
          <div className="text-block-22">Wizard Walkthrough</div>
          <div className="text-block-23">
            Automatically choosen relevant workflow, based on set of questions{" "}
          </div>
        </div>
      </div>

      <div
        className="div-workflow"
        style={{
          display: analysisStep === 0 ? "flex" : "none",
          height: "100vh",
        }}
      >
        <div className="div-block-65" />
        {/* {["RNA-Seq alignment", "RNA-Seq DB Build", "RNA-Seq Full"] */}
        {rootWorkflows.map((rwf, i) => {
          console.log(rwf)
          return (
            <div
              className="div-block-64"
              key={i}
              onClick={() => {
                if (!rwf.disabled) {
                  handleSelectWorkflow();
                  setSelectedRootWorkflow(rwf);
                  setSelectedWorkflow(
                    workflows.filter(
                      (_wf) => _wf.name === rwf.defaultLinkedWorkFlow
                    )[0]
                  );
                }
              }}
              style={{
                cursor: rwf.disabled ? "not-allowed" : "",
              }}
            >
              <div
                class="overlay-soon"
                style={{
                  display: rwf.disabled ? "block" : "none",
                }}
              >
                Coming Soon
              </div>
              <img
                src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/61b86eff3613d1659d0acc31_icons8-workflow-96.png"
                loading="lazy"
                width={42}
                alt=""
              />
              <div className="text-block-22">{rwf.displayName}</div>
              <div className="text-block-23">{rwf.description}</div>
            </div>
          );
        })}
      </div>

      <div
        className="section-2 wf-section"
        style={{
          display: analysisStep === 1 ? "flex" : "none",
          alignItems: "center",
        }}
      >
        <AnalysisWizard setAnalysisStep={setAnalysisStep} />
      </div>

      <div
        className="section-2 wf-section"
        style={{
          display: analysisStep === 2 ? "flex" : "none",
        }}
      >
        {/* <div className="div-block-2">
          <div className="div-block-3">Overview</div>
          <div className="div-block-3 active">Project Summery</div>
          <div className="div-block-3">Files</div>
        </div> */}
        <div className="div-analysis-nav">
          <div className="div-block-24">
            <div className="text-block-4">{selectedWorkflow?.displayName}</div>
          </div>
          <div className="text-block-9">{/* Description.. */}</div>
          <div className="div-analysis-desc" />
        </div>

        <div className="div-main">
          {/* <div className="div-main"> */}
          <div className="div-block-87">
            <div className="div-block-90">
              <div className="ver-line" />
              {steps.map((step, i) => {
                return (
                  <div
                    className={`div-block-88 ${
                      selectedStep === i || selectedStep > i ? "active" : ""
                    }`}
                    key={i}
                    onClick={() => setSelectedStep(i)}
                  >
                    <div
                      className={`text-block-49 ${
                        selectedStep === i ? "active" : ""
                      }`}
                    >
                      {step.name}
                    </div>
                    <div
                      className={`div-block-89 ${
                        selectedStep === i || selectedStep > i ? "active" : ""
                      }`}
                    >
                      {i + 1}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="div-block-91">
            <div
              className="analysis-statergy-block"
              style={{
                display:
                  steps[selectedStep].name !== "Analysis Strategy"
                    ? "none"
                    : "flex",
              }}
            >
              <Strategy
                setStrategyOut={setStrategyOut}
                workflow={selectedWorkflow}
                setSelectedWorkflow={setSelectedWorkflow}
                selectedRootWorkflow={selectedRootWorkflow}
              />
            </div>

            <div
              className="analysis-inputf-block"
              style={{
                display:
                  steps[selectedStep].name !== "Input Files" ? "none" : "flex",
              }}
            >
              <InputFiles
                plf={plf}
                inputFileOut={inputFileOut}
                setInputFileOut={setInputFileOut}
                selectedRootWorkflow={selectedRootWorkflow}
                workflow={selectedWorkflow}
              />
            </div>

            <div
              className="analysis-inputf-block"
              style={{
                display:
                  steps[selectedStep].name !== "Reference File"
                    ? "none"
                    : "flex",
              }}
            >
              <ReferenceFile
                plf={plf}
                setReferenceFileOut={setReferenceFileOut}
                selectedRootWorkflow={selectedRootWorkflow}
                workflow={selectedWorkflow}
              />
            </div>

            <div
              className="analysis-inputf-block"
              style={{
                display:
                  steps[selectedStep].name !== "Experimental Design"
                    ? "none"
                    : "flex",
              }}
            >
              <ExperimentalDesign
                plf={plf}
                inputFileOut={inputFileOut}
                setExpDesign={setExpDesign}
                setExpDesignOut={setExpDesignOut}
                workflow={selectedWorkflow}
              />
            </div>
            <div
              className="analysis-inputf-block"
              style={{
                display:
                  steps[selectedStep].name !== "Comparison Selection"
                    ? "none"
                    : "flex",
              }}
            >
              <ComparisonSelection
                expDesign={expDesign}
                setComparisonSelectionOut={setComparisonSelectionOut}
                workflow={selectedWorkflow}
              />
            </div>

            <div
              className="analysis-summary-block"
              style={{
                display:
                  steps[selectedStep].name !== "Summary" ? "none" : "flex",
              }}
            >
              <Summary
                workflow={selectedWorkflow}
                comparisonSelectionOut={comparisonSelectionOut}
                referenceFileOut={referenceFileOut}
                inputFileOut={inputFileOut}
                vmSpeed={vmSpeed}
                setVmSpeed={setVmSpeed}
              />
            </div>

            {steps[selectedStep].name !== "Summary" ? (
              <div
                className="div-block-93"
                style={{
                  padding: "7%",
                }}
              >
                <div className="div-block-94">
                  <div
                    className="prev-next-btn"
                    onClick={() =>
                      setSelectedStep((x) => {
                        if (x != 0) {
                          return x - 1;
                        } else {
                          return x;
                        }
                      })
                    }
                  >
                    Back
                  </div>
                  <div
                    className="prev-next-btn next"
                    onClick={() => {
                      setSelectedStep((x) => {
                        if (x < steps.length - 1) {
                          return x + 1;
                        }
                      });
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="div-block-93"
                style={{
                  padding: "7%",
                }}
              >
                <div className="div-block-94">
                  <div className="analysis-start-btn" onClick={startAnalysis}>
                    Start Analysis
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Experimental Design Modal */}

      {/* --- Alignment Modal --- */}
      <Modal
        show={showAlignmentModal}
        onHide={handleCloseAlignmentModal}
        // backdrop="static"
        keyboard={true}
        // fullscreen={true}
        centered
        // size="lg"
        dialogClassName="div-block-12"
        // contentClassName="div-block-12"
      >
        {/* <Modal.Body> */}
        {/* <div className="div-block-12" style={{
          width: "100%"
        }}> */}
        <div className="div-block-13">
          <div className="div-block-25">
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60fc3a2f21621b84f7932381_icons8-back-96.png"
              loading="lazy"
              width={16}
              alt=""
            />
            <div className="text-block-3">Back</div>
          </div>
          <div className="text-block-2">Alignment</div>
          <div />
        </div>
        <div className="div-block-27">
          <div className="div-block-39">Star</div>
          <div className="div-block-39">Bowtie2</div>
          <div className="div-block-39">Burrows-Wheeler Aligner (BWA)</div>
          <div className="div-block-39">HiSat2</div>
          <div className="div-block-39">Tophat</div>
        </div>
        <div className="div-block-29">
          <div className="div-block-30">
            <div className="div-block-43">
              <div className="text-block-18">Star:</div>
              <div className="btn-2">
                <div className="btn-2-text">Version 2.0</div>
                <img
                  src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60fc2f152b2dc0e3c9a69611_icons8-chevron-down-90.png"
                  loading="lazy"
                  width={22}
                  alt=""
                  className="image"
                />
              </div>
            </div>
            <div className="text-block-17">Application:</div>
            <p className="text-block-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
            <div className="text-block-17">Use Case:</div>
            <p className="text-block-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
            <div className="text-block-17">Input Requirments</div>
            <p className="text-block-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
          </div>
          <div className="div-block-31">
            <div className="text-block-17 center">App Information</div>
            <p className="text-block-8 center">
              <a href="#" className="link">
                Homepage
              </a>
              <br />
              <br />
              <a href="#" className="link">
                Publication
                <br />
              </a>
              <br />
              <a href="#" className="link">
                Source Code
              </a>
              <br />
              <br />
              <a href="#" className="link">
                License
              </a>
            </p>
          </div>
        </div>
        <div className="div-block-26">
          <div className="div-block-40">
            <div className="div-block-41">Inputs</div>
            <div className="div-block-41">Settings</div>
            <div className="div-block-41">Outputs</div>
          </div>
          <div className="div-block-42" />
        </div>
        {/* </div>/ */}
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
};

export default Analysis;
