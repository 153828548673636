import api from '../../api/projects'
import { toast } from 'react-toastify'
import {
  ADD_ADMIN_USERS,
  DELETE_ADMIN_USERS,
  GET_ADMIN_USER,
  GET_ADMIN_USERS,
  UPDATE_ADMIN_USER,
  UPDATE_IS_DISABLED,
  RESET_MFA,
  GET_ADMIN_USERS_LOADING,
} from '../types'

export const getAdminUsers = () => async (dispatch) => {
  dispatch({ type: GET_ADMIN_USERS_LOADING })
  const response = await api.get('/internaladmin/organizations')
  if (response.status === 200) {
    dispatch({ type: GET_ADMIN_USERS, payload: response.data })
  }
}

export const deleteAdminUsers = (id) => async (dispatch) => {
  // console.log("id", id)
  const data = {
    _id: id,
  }
  // console.log("data", data)
  const response = await api.delete('/internaladmin/organizations', { data: data })

  dispatch({ type: DELETE_ADMIN_USERS, payload: id })
  if (response.status === 200) {
    toast.success("User deleted successfully")
    dispatch(getAdminUsers())
  }
  else {
    toast.error("somthing error")
  }
}

export const addAdminUsers = (data) => async (dispatch) => {
  const response = await api.post('/internaladmin/organizations', data)

  dispatch({ type: ADD_ADMIN_USERS, payload: data })
  if (response.status === 200) {
    toast.success("User added successfully")
  }
  else {
    toast.error("somthing error")
  }
}

export const updateIsDisabled = (id, data) => async (dispatch) => {
  const patchData = {
    _id: id,
    is_disabled: data,
  }
  const response = await api.patch(
    '/internaladmin/organizations/disabled',
    patchData,
  )
  dispatch({ type: UPDATE_IS_DISABLED, payload: data })
  console.log("respones", response)
  if (response.status === 200) {
    toast.success("User access updated successfully");
  }
  else {
    toast.error("somthing error")
  }
}
export const MFAResetStatus = (id, data) => async (dispatch) => {
  const patchData = {
    _id: id,
    resetMFA: data,
  }
  const response = await api.patch(
    '/user/resetMFAStatus',
    patchData,
  )
  dispatch({ type: RESET_MFA, payload: data })
  console.log("respones", response)
  if (response.status === 200) {
    toast.success("MFA reset successfully");
  }
  else {
    toast.error("somthing error")
  }
}

export const getAdminUser = (id) => async (dispatch) => {
  const response = await api.get(`/internaladmin/organizations/`)
  dispatch({ type: GET_ADMIN_USER, payload: response.data })
}

export const updateAdminUser = (user) => async (dispatch) => {
  const response = await api.put('/internaladmin/organizations', user)
  dispatch({ type: UPDATE_ADMIN_USER, payload: user })
  if (response.status === 200) {
    toast.success("User updated successfully")
  }
  else {
    toast.error("somthing error")
  }
}
