import React, { useEffect, useRef } from 'react';

const IGVConifg = ({ options }) => {
  const igvContainerRef = useRef(null);

  useEffect(() => {
    if (igvContainerRef.current) {
      // Initialize IGV
      window.igv.createBrowser(igvContainerRef.current, options)
        .then((browser) => {
          console.log("IGV Browser is ready");
          // You can use the browser variable to interact with the IGV instance
        })
        .catch((error) => console.error('Error creating IGV browser:', error));
    }
  }, [options]); // Reinitialize if options change

  return <div ref={igvContainerRef} />;
};


export default IGVConifg;
