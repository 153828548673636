import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import auth from "../services/AuthService";
import VerificationComponent from "../components/verification/Verification.component";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {

  const currentUser = useSelector((state) => state.projects.currentUser);


  return (
    <Route
      {...rest}
      render={(props) => {

        if (currentUser) {
          return <Component {...props} />;
        } else {
          return <VerificationComponent {...props} />;
          //window.location = `https://drive.skygenic.com/#!/login?returnUrl=${window.location.href}`
        }
      }}
    />
  );
};

export default ProtectedRoute;
