import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getDivision,
  updateSingleUserAdminUsers,
} from "../../../actions/userAdmin/actionCreators";
import { useHistory, useLocation } from "react-router";
import closeCircle from '../../../assets/Circle-X-close.png';
import uparrow from "../../../assets/Chevron-Up.png";
import downarrow from "../../../assets/Chevron-Down.png";

const OutsideAlerter = ({ children, setOpen }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  return <div ref={wrapperRef}>{children}</div>;
};

function UpdateDivisionUser() {
  const navigate = useHistory();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const divisionData = useSelector((state) => state.userAdmin.division);
  // const singleUser = useSelector((state) => state.userAdmin.singleUserAdminuser)
  const currentUser = useSelector((state) => state.projects.currentUser);
  const selectedOrg = useSelector((state) => state.userAdmin.org_id);
  const [divisions, setDivisions] = useState([]);
  const [open, setOpen] = useState(false);

  const orgData = useMemo(() => ({
    orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin"
      ? currentUser.org_id
      : selectedOrg,
  }), [currentUser.roles, currentUser.org_id, selectedOrg]);

  const [switchDisabled, setSwitchDisabled] = useState(
    location.state.userData.is_disabled
  );
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const divisionList = useMemo(() => {
    let result = divisionData.filter((item) => {
      if (
        currentUser.roles === "internal admin" ||
        currentUser.roles === "company admin"
      ) {
        return item;
      } else {
        return currentUser?.divisions.some(
          (item2) => item2.divisionId === item._id
        );
      }
    });
    return result.map((item) => ({
      value: item._id,
      label: item.division_name,
    }));
  }, [divisionData, currentUser.roles, currentUser.divisions]);

  const handlechange = useCallback((e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  }, []);

  useEffect(() => {

    const initialFormData = {
      _id: location.state.userData._id,
      username: location.state.userData.username,
      nickname: location.state.userData.nickname.trim(),
      first_name: location.state.userData.first_name.trim(),
      last_name: location.state.userData.last_name.trim(),
      email: location.state.userData.email,
      roles: location.state.userData.roles,
      updated_by: currentUser.id,
      created_by: location.state.userData.created_by,
      updated_at: "",
      organisation_id: location.state.userData.organisation_id,
    };
    setFormValues(initialFormData);
    dispatch(getDivision(orgData));
  }, [location.state.userData, currentUser.id, dispatch, orgData]);

  useEffect(() => {
    let result = divisionData.filter((item) => {
      return location.state.userData?.divisions.some(
        (item2) => item2.divisionId === item._id
      );
    });

    const div = result.map((item) => ({
      value: item._id,
      label: item.division_name,
    }));

    setDivisions(div);
  }, [divisionData, location.state.userData])
  
  // const handledivOnchange = useCallback((e) => {
  //   const { value } = e.target;
  //   setDivisionId(value);
  // }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const ref = /^[a-zA-Z0-9\s]+$/;
    const eml = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const newErrors = {};

    const validateField = (field, errorMsg) => {
      if (!formValues[field].trim()) {
        newErrors[field] = errorMsg;
      } else if (field === 'email' && !eml.test(formValues[field])) {
        newErrors[field] = "Enter valid Email";
      } else if ((field === 'first_name' || field === 'last_name') && !ref.test(formValues[field])) {
        newErrors[field] = "Special characters not allowed";
      }
    };

    validateField('email', 'Field is empty');
    validateField('first_name', 'Field is empty');
    validateField('last_name', 'Field is empty');
    if (!formValues.roles) newErrors.roles = "Field is empty";
    if (divisions.map(div => ({ divisionId: div.value })).length === 0) newErrors.division_id = "Field is empty";
    if (!formValues.organisation_id) newErrors.organisation_id = "Field is empty";

    setFormErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const formData = {
        ...formValues,
        username: formValues.email,
        nickname: `${formValues.first_name} ${formValues.last_name}`,
        is_disabled: switchDisabled,
        updated_at: Math.floor(Date.now() / 1000),
        divisions: divisions.map(div => ({ divisionId: div.value })),
      };
      dispatch(updateSingleUserAdminUsers(formData));

      setTimeout(() => {
        navigate.push("/admin/users/divisions");
        setFormValues((prevValues) => ({
          ...prevValues,
          username: "",
          email: "",
          nickname: "",
          first_name: "",
          last_name: "",
          updated_at: "",
          is_disabled: switchDisabled,
        }));
      }, 600);
    }
  }, [formValues, switchDisabled, dispatch, navigate,divisions]);

  const handleBack = useCallback((e) => {
    e.preventDefault();
    history.goBack();
  }, [history]);

  const closeDropDown = useCallback(() => {
    setDivisions([]);
    setOpen(false);
  }, []);

  const handleCheckboxChange = useCallback((value) => {
    setDivisions([{ value, label: value }]);
  }, []);

  return (
    <div className="mod">
      <Modal.Body className="">
        <div className="pull"> </div>
        <h4 className="text-center">UPDATE DIVISION USER</h4>
        <form>
          <div className="row m-3">
            <div className="col ">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={handlechange}
                name="first_name"
                value={formValues.first_name}
              />
              <span className="text-danger"> {formErrors.first_name} </span>
            </div>
            <div className="col">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                onChange={handlechange}
                name="last_name"
                value={formValues.last_name}
              />
              <span className="text-danger">{formErrors.last_name} </span>
            </div>
          </div>
          <div className="row m-3">
            <div className="col">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                onChange={handlechange}
                name="email"
                value={formValues.email}
              />
              <span className="text-danger">{formErrors.email} </span>
            </div>
            <div className="col add-user-select">
              <label htmlFor="roles">Role</label>
              <select
                name="roles"
                value={formValues.roles}
                onChange={handlechange}>
                <option value="member">Member</option>
                <option value="view only">View only</option>
              </select>
              <span className="text-danger"> {formErrors.roles} </span>
            </div>
          </div>
          <div className="row m-3">
            {/* <div className="col add-user-select">
              <label htmlFor="division_id">Division</label>
              <select
                name="divisionId"
                value={divisionId}
                onChange={handledivOnchange}
              >
                <option value="">please select your division name</option>
                {divisionList.map((item) => (
                  <option value={item._id} key={item._id}>
                    {item.division_name}
                  </option>
                ))}
              </select>
              <span className="text-danger"> {formErrors.division_id} </span>
            </div> */}
            <div className="col add-user-select">
              <div className="multi-select-dropdown">
                <label htmlFor="division_id">Division Name</label>
                <OutsideAlerter setOpen={setOpen}>
                  <div className="multi-select-dropdown_wrapper">
                    <div className='multi-select-dropdown-input' onClick={() => setOpen((prev) => !prev)}>
                      {divisions.length ? `${divisions.length} Division selected` : "Division Name"}
                    </div>
                    <div className='multi-select-dropdown-direction'>
                      <img src={closeCircle} height={18} onClick={closeDropDown} alt='closeIcon' />
                      {open ? <img src={downarrow} height={18} onClick={() => setOpen(false)} alt='downIcon' /> : <img src={uparrow} height={18} onClick={() => setOpen(true)} alt='upIcon' />}
                    </div>
                  </div>
                  <div className="multi-select-dropdown_options_wrapper" style={{ display: open ? "block" : "none" }}>
                    <ul className="multi-select-dropdown_options">
                      {divisionList.map(option => {
                        return (
                          <li className="multi-select-dropdown_option" key={option.value} onClick={() => handleCheckboxChange(option.value)}>
                            <input
                              type="radio"
                              name="options"
                              value={option.value}
                              checked={divisions.find((val) => val.value === option.value)}
                              onChange={() => { }}
                              className="c-multi-select-dropdown_option-checkbox"></input>
                            <span>{option.label}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </OutsideAlerter>
                <span className="text-danger" style={{ fontSize: "14px", fontWeight: "400" }}>{formErrors.division_id} </span>
              </div>
            </div>
            <div className="col">
              <p className="edit-access">Access</p>
              <BootstrapSwitchButton
                data-onstyle="outline-primary"
                data-offstyle="outline-secondary"
                checked={switchDisabled === true ? false : true}
                onChange={() => {
                  setSwitchDisabled(!switchDisabled);
                }}
              />
            </div>
          </div>
          <div className="row m-3">
            <div style={{ marginTop: "10px" }}>
              <p style={{ color: "red" }}>
                * NOTE:- A division must first be formed before appointing a
                division user
              </p>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={handleSubmit}
              className="add-btn btn btn-primary  m-3"
            >
              Update User
            </button>
            <button
              onClick={handleBack}
              className="add-btn btn btn-primary  m-3"
            >
              Back
            </button>
          </div>
        </form>
      </Modal.Body>
    </div>
  );
}

export default UpdateDivisionUser;
