import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { addPublicDataFilesToProject } from "../../actions/projects";
import { setLocale } from "yup";

const toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
});

const PublicDataExplorer = ({
  projectId,
  showPublicFilesModal,
  setShowPublicFilesModal,
  onHide,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.projects.currentUser);

  const [searchTerm, setSearchTerm] = useState("");
  const [publicData, setPublicData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [publicData2, setPublicData2] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [openTabs, setOpenTabs] = useState([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  function getCheckedItemsString(checkedItems) {
    const checkedKeys = Object.keys(checkedItems).filter((key) => checkedItems[key]);
    return checkedKeys.length > 0 ? checkedKeys.join("[All Fields] AND ") + "[All Fields]" : "";
  }

  const handleSearchClick = async () => {
    const checkedPlatformsString = getCheckedItemsString(checkedPlatforms);
    const checkedFormatsString = getCheckedItemsString(checkedFormats);
    const checkedStrategiesString = getCheckedItemsString(checkedStrategies);
    const checkedOrganismsString = getCheckedItemsString(checkedOrganisms);
    const checkedInstrumentsString = getCheckedItemsString(checkedInstruments);

    const combinedSearchTerm = [
      searchTerm ? searchTerm + "[All Fields]" : "",
      checkedPlatformsString,
      checkedFormatsString,
      checkedStrategiesString,
      checkedInstrumentsString,
      checkedOrganismsString,
    ]
      .filter(Boolean)
      .join(" AND ");

    console.log(combinedSearchTerm);
    // setSearchTerm(combinedSearchTerm)


    const response = await axios.get(
      `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=sra&usehistory=y&retmode=json&term=${combinedSearchTerm}`
    );
    let webenv = response.data.esearchresult.webenv;
    const summaryResponse = await axios.get(
      `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=sra&retmode=json&query_key=1&WebEnv=${webenv}&retstart=0&retmax=500`
    );
    let gg = summaryResponse.data;
    let data = [];
    if (gg.result) {
      data = Object.keys(gg.result)
        .filter((key) => key !== "uids")
        .map((key) => ({
          id: key,
          ...gg.result[key],
        }));
    } else {
      // setTableLoading(false);
      data = [];
      gg.result = {};
    }

    console.log(data);
    setPublicData(data);

    function extractSRPandSRR(objects) {
      const resultArray = [];
      const mapping = {};

      Object.values(objects).forEach((obj) => {
        if (!obj.expxml || !obj.runs) {
          return; // Skip this iteration if `expxml` or `runs` is missing or undefined
        }

        const srpMatch = obj.expxml.match(/<Study acc="(\w+)" name="([^"]+)"/);
        const srrMatch = obj.runs.match(/<Run acc="(\w+)"/);
        const instrumentMatch = obj.expxml.match(/instrument_model="([^"]+)"/);

        if (srpMatch && srrMatch) {
          const srp = srpMatch[1];
          const srr = srrMatch[1];
          const studyName = srpMatch[2];
          const instrument = instrumentMatch ? instrumentMatch[1] : undefined;

          if (!mapping[srp]) {
            mapping[srp] = {
              studyId: srp,
              studyName: studyName,
              instruments: new Set(), // Use a Set to store unique instruments
              srrs: [],
            };
          }

          mapping[srp].srrs.push(srr);
          if (instrument) {
            mapping[srp].instruments.add(instrument);
          }
        }
      });

      // Convert the mapping object to an array of objects
      Object.keys(mapping).forEach((srp) => {
        const entry = mapping[srp];
        entry.instruments = Array.from(entry.instruments); // Convert Set to Array
        resultArray.push(entry);
      });
      setTableLoading(false);

      return resultArray;
    }

    // Sample usage with your data
    let SRP_set;
    try {
      SRP_set = extractSRPandSRR(gg.result);
      console.log(SRP_set);
    } catch (error) {
      console.error("Error extracting SRP and SRR data:", error);
    }

    console.log(SRP_set);
    setPublicData2(SRP_set);
  };

  const columns1 = [
    {
      accessorKey: "studyId",
      header: "Study ID",
    },
    {
      accessorKey: "studyName",
      header: "Title",
    },
    {
      accessorKey: "srrs",
      header: "Number of Files",
      Cell: ({ renderedCellValue }) => (
        <span>
          {renderedCellValue === null ? "" : renderedCellValue.length}
        </span>
      ),
    },
    {
      accessorKey: "instruments",
      header: "Instruments",
      Cell: ({ renderedCellValue, row }) => (
        <span style={{ margin: "5px" }}>
          {renderedCellValue &&
            renderedCellValue.map((tag, index) => (
              <span className="tag" key={index}>
                {tag}
              </span>
            ))}
        </span>
      ),
    },
  ];

  // Define the columns for the table
  const inputColumns = [
    // {
    //   header: "UID",
    //   accessorKey: "uid",
    //   sortable: true,
    // },
    {
      header: "Accession",
      accessorKey: "runs",
      Cell: ({ row }) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(row.original.runs, "text/xml");
        const runAcc = xmlDoc
          .getElementsByTagName("Run")[0]
          .getAttribute("acc");
        return <span>{runAcc}</span>;
      },
    },
    {
      header: "Experiment Title",
      accessorKey: "expxml",
      Cell: ({ row }) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(row.original.expxml, "text/xml");
        const title =
          xmlDoc.getElementsByTagName("Title")[0].childNodes[0].nodeValue;
        return <div>{title}</div>;
      },
    },
    {
      header: "Instrument Model",
      accessorKey: "expxml",
      Cell: ({ row }) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(row.original.expxml, "text/xml");
        const instrumentModel = xmlDoc
          .getElementsByTagName("Platform")[0]
          .getAttribute("instrument_model");
        return <div>{instrumentModel}</div>;
      },
    },
    {
      header: "Total Runs",
      accessorKey: "expxml",
      Cell: ({ row }) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(row.original.expxml, "text/xml");
        const totalRuns = xmlDoc
          .getElementsByTagName("Statistics")[0]
          .getAttribute("total_runs");
        return <div>{totalRuns}</div>;
      },
    },
    {
      header: "Total Bases (Mb)",
      accessorKey: "expxml",
      Cell: ({ row }) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(row.original.expxml, "text/xml");
        const totalBases = xmlDoc
          .getElementsByTagName("Statistics")[0]
          .getAttribute("total_bases");
        const totalBasesInMb = totalBases / 100000; // Converting bases to megabases
        return <span>{totalBasesInMb.toFixed(2)} Mb</span>;
      },
    },
    // {
    //   header: "Species",
    //   accessorKey: "expxml",
    //   Cell: ({ row }) => {
    //     const parser = new DOMParser();
    //     const xmlDoc = parser.parseFromString(row.original.expxml, "text/xml");
    //     const organismElements = xmlDoc.getElementsByTagName("Organism");

    //     // Check if the Organism element exists
    //     if (organismElements.length > 0) {
    //       const scientificName = organismElements[0].getAttribute("ScientificName");
    //       return <div>{scientificName}</div>;
    //     } else {
    //       // Handle the case where Organism is not found
    //       return <div>Not available</div>;
    //     }
    //   },
    // },
    {
      header: "Create Date",
      accessorKey: "createdate",
      sortable: true,
    },
    {
      header: "Update Date",
      accessorKey: "updatedate",
      sortable: true,
    },
  ];

  // -------------------------------

  const filtersData = {
    platforms: [
      { id: "illumina", name: "Illumina" },
      { id: "ion_torrent", name: "Ion Torrent" },
      { id: "pacbio", name: "PacBio" },
      { id: "oxford_nanopore", name: "Oxford Nanopore " },
      { id: "sanger_sequencing", name: "Sanger Sequencing" },
      { id: "454", name: "454 Life Sciences (Roche)" },
      { id: "dnbseq", name: "DNBSEQ (MGI Tech)" },
      { id: "abi_solid", name: "ABI SOLiD " },
    ],
    formats: [
      { id: "fastq", name: "FASTQ" },
      { id: "bam", name: "BAM" },
      { id: "vcf", name: "VCF" },
      { id: "fasta", name: "FASTA" },
      { id: "gff", name: "GFF" },
      { id: "bed", name: "BED" },
      { id: "sam", name: "SAM" },
      { id: "wig", name: "WIG" },
    ],
    strategies: [
      { id: "wgs", name: "Whole Genome Sequencing (WGS)" },
      { id: "rna_seq", name: "RNA Sequencing (RNA-Seq)" },
      { id: "chip_seq", name: "ChIP Sequencing (ChIP-Seq)" },
      { id: "exome_seq", name: "Exome Sequencing (Exome-Seq)" },
      { id: "amplicon_seq", name: "Amplicon Sequencing" },
      { id: "metagenomic", name: "Metagenomic Sequencing" },
      { id: "single_cell", name: "Single Cell Sequencing" },
      { id: "other", name: "Other" },
    ],
    organisms: [
      { id: "homo_sapiens", name: "Homo sapiens (Human)" },
      { id: "mus_musculus", name: "Mus musculus (Mouse)" },
      {
        id: "arabidopsis_thaliana",
        name: "Arabidopsis thaliana (Thale Cress)",
      },
      { id: "escherichia_coli", name: "Escherichia coli (E. coli)" },
      {
        id: "saccharomyces_cerevisiae",
        name: "Saccharomyces cerevisiae (Yeast)",
      },
      {
        id: "drosophila_melanogaster",
        name: "Drosophila melanogaster (Fruit Fly)",
      },
      {
        id: "caenorhabditis_elegans",
        name: "Caenorhabditis elegans (Nematode Worm)",
      },
      { id: "danio_rerio", name: "Danio rerio (Zebrafish)" },
      { id: "rattus_norvegicus", name: "Rattus norvegicus (Rat)" },
      { id: "zea_mays", name: "Zea mays (Maize)" },
      { id: "oryza_sativa", name: "Oryza sativa (Rice)" },
      { id: "gallus_gallus", name: "Gallus gallus (Chicken)" },
      { id: "pan_troglodytes", name: "Pan troglodytes (Chimpanzee)" },
      {
        id: "xenopus_tropicalis",
        name: "Xenopus tropicalis (Western Clawed Frog)",
      },
      { id: "pisum_sativum", name: "Pisum sativum (Pea)" },
      { id: "cucumis_sativus", name: "Cucumis sativus (Cucumber)" },
      { id: "solanum_lycopersicum", name: "Solanum lycopersicum (Tomato)" },
      { id: "solanum_tuberosum", name: "Solanum tuberosum (Potato)" },
      { id: "brassica_napus", name: "Brassica napus (Canola/Rapeseed)" },
      { id: "hordeum_vulgare", name: "Hordeum vulgare (Barley)" },
    ],
    instruments: [
      { id: "illumina_hiseq", name: "Illumina HiSeq" },
      { id: "illumina_miseq", name: "Illumina MiSeq" },
      { id: "illumina_nextseq", name: "Illumina NextSeq" },
      { id: "illumina_novaseq", name: "Illumina NovaSeq" },
      { id: "pacbio_sequel", name: "PacBio Sequel" },
      { id: "pacbio_rs_ii", name: "PacBio RS II" },
      { id: "oxford_minion", name: "Oxford Nanopore MinION" },
      { id: "oxford_promethion", name: "Oxford Nanopore PromethION" },
      { id: "oxford_gridion", name: "Oxford Nanopore GridION" },
      { id: "ion_torrent_pgm", name: "Ion Torrent PGM" },
      { id: "ion_torrent_s5", name: "Ion Torrent S5" },
      { id: "ion_torrent_proton", name: "Ion Torrent Proton" },
      { id: "bgi_mgiseq", name: "BGI/MGI MGISEQ" },
      { id: "bgi_dnbseq_g400", name: "BGI/MGI DNBSEQ-G400" },
      { id: "bgi_dnbseq_t7", name: "BGI/MGI DNBSEQ-T7" },
      { id: "roche_454_gs_flx", name: "Roche 454 GS FLX" },
      { id: "roche_454_gs_junior", name: "Roche 454 GS Junior" },
      { id: "abi_solid", name: "ABI SOLiD System" },
    ],
  };

  const [checkedPlatforms, setCheckedPlatforms] = useState({});
  const [checkedFormats, setCheckedFormats] = useState({});
  const [checkedStrategies, setCheckedStrategies] = useState({});
  const [checkedOrganisms, setCheckedOrganisms] = useState({});
  const [checkedInstruments, setCheckedInstruments] = useState({});

  const handleCheckboxChange = (category, id) => (event) => {
    const updateState = { ...category, [id]: event.target.checked };
    if (filtersData.platforms.some((platform) => platform.id === id)) {
      setCheckedPlatforms(updateState);
    } else if (filtersData.formats.some((format) => format.id === id)) {
      setCheckedFormats(updateState);
    } else if (filtersData.strategies.some((strategy) => strategy.id === id)) {
      setCheckedStrategies(updateState);
    } else if (filtersData.organisms.some((organism) => organism.id === id)) {
      setCheckedOrganisms(updateState);
    } else if (
      filtersData.instruments.some((instrument) => instrument.id === id)
    ) {
      setCheckedInstruments(updateState);
    }
  };

  const handleClose = () => {
    // Get the selected row values items wrt publicdata2 in material table

    if (
      Object.keys(publicData2).length === 0 &&
      publicData2.constructor === Object
    ) {
      console.log("No data to add to project");
      onHide();
      return;
    }

    const selectedRows = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .map(Number); // convert string to number

    console.log(selectedRows, publicData2);
    const selectedFiles = publicData2.filter((row, i) =>
      selectedRows.includes(i)
    );
    console.log(selectedFiles);

    dispatch(
      addPublicDataFilesToProject(projectId, {
        files: selectedFiles.map((file) => file.studyId),
        userId: currentUser.id,
      })
    );

    toast.fire({
      icon: "success",
      title: "Files added to project",
    });
    onHide();
  };
  return (
    <>
      <Modal
        dialogClassName="modal-98w"
        show={showPublicFilesModal}
        onHide={handleClose}
        size="lg"
        centered
        style={{
          overflowY: "auto",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Public Data Explorer</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "0px",
            overflow: "auto",
            height: "83vh",
          }}
        >
          <div className="div-block-103">
            <div className="div-block-105-copy">
              <div className="pde-heading">Search Gene Accession Number</div>
              <div className="search-1">
                <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                &nbsp;
                <TextField
                  id="input-with-sx"
                  style={{
                    width: "100%",
                  }}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <Button
                  onClick={() => {
                    handleSearchClick();
                    setTableLoading(true);
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
            <div className="sra-data-block">
              <div className="sra-filter-wrapper">
                <div className="filter-block">
                  <div
                    className="filter-title"
                    onClick={() => {
                      if (openTabs.includes("platforms")) {
                        setOpenTabs(
                          openTabs.filter((tab) => tab !== "platforms")
                        );
                      } else {
                        setOpenTabs([...openTabs, "platforms"]);
                      }
                    }}
                  >
                    Platform
                    {openTabs.includes("platforms") ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </div>

                  {openTabs.includes("platforms") &&
                    filtersData.platforms.map((platform) => (
                      <div className="filter-option-checkbox" key={platform.id}>
                        <input
                          type="checkbox"
                          id={platform.id}
                          name={platform.name}
                          checked={checkedPlatforms[platform.id] || false}
                          onChange={handleCheckboxChange(
                            checkedPlatforms,
                            platform.id
                          )}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <label
                          htmlFor={platform.id}
                          style={{
                            margin: "0px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {platform.name}
                        </label>
                      </div>
                    ))}

                  {/* </div> */}
                </div>

                <div className="filter-block">
                  <div
                    className="filter-title"
                    onClick={() => {
                      if (openTabs.includes("formats")) {
                        setOpenTabs(
                          openTabs.filter((tab) => tab !== "formats")
                        );
                      } else {
                        setOpenTabs([...openTabs, "formats"]);
                      }
                    }}
                  >
                    Format {openTabs.includes("formats") ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </div>

                  {openTabs.includes("formats") &&
                    filtersData.formats.map((format) => (
                      <div className="filter-option-checkbox" key={format.id}>
                        <input
                          type="checkbox"
                          id={format.id}
                          name={format.name}
                          checked={checkedFormats[format.id] || false}
                          onChange={handleCheckboxChange(
                            checkedFormats,
                            format.id
                          )}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <label
                          htmlFor={format.id}
                          style={{
                            margin: "0px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {format.name}
                        </label>
                      </div>
                    ))}
                </div>

                <div className="filter-block">
                  <div
                    className="filter-title"
                    onClick={() => {
                      if (openTabs.includes("strategies")) {
                        setOpenTabs(
                          openTabs.filter((tab) => tab !== "strategies")
                        );
                      } else {
                        setOpenTabs([...openTabs, "strategies"]);
                      }
                    }}
                  >
                    Strategy {openTabs.includes("strategies") ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </div>

                  {openTabs.includes("strategies") &&
                    filtersData.strategies.map((strategy) => (
                      <div className="filter-option-checkbox" key={strategy.id}>
                        <input
                          type="checkbox"
                          id={strategy.id}
                          name={strategy.name}
                          checked={checkedStrategies[strategy.id] || false}
                          onChange={handleCheckboxChange(
                            checkedStrategies,
                            strategy.id
                          )}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <label
                          htmlFor={strategy.id}
                          style={{
                            margin: "0px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {strategy.name}
                        </label>
                      </div>
                    ))}
                </div>

                <div className="filter-block">
                  <div
                    className="filter-title"
                    onClick={() => {
                      if (openTabs.includes("instruments")) {
                        setOpenTabs(
                          openTabs.filter((tab) => tab !== "instruments")
                        );
                      } else {
                        setOpenTabs([...openTabs, "instruments"]);
                      }
                    }}
                  >
                    Instrument {openTabs.includes("instruments") ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </div>

                  {openTabs.includes("instruments") &&
                    filtersData.instruments.map((instrument) => (
                      <div
                        className="filter-option-checkbox"
                        key={instrument.id}
                      >
                        <input
                          type="checkbox"
                          id={instrument.id}
                          name={instrument.name}
                          checked={checkedInstruments[instrument.id] || false}
                          onChange={handleCheckboxChange(
                            checkedInstruments,
                            instrument.id
                          )}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <label
                          htmlFor={instrument.id}
                          style={{
                            margin: "0px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {instrument.name}
                        </label>
                      </div>
                    ))}
                </div>

                <div className="filter-block">
                  <div
                    className="filter-title"
                    onClick={() => {
                      if (openTabs.includes("organisms")) {
                        setOpenTabs(
                          openTabs.filter((tab) => tab !== "organisms")
                        );
                      } else {
                        setOpenTabs([...openTabs, "organisms"]);
                      }
                    }}
                  >
                    Organism {openTabs.includes("organisms") ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </div>

                  {openTabs.includes("organisms") &&
                    filtersData.organisms.map((organism) => (
                      <div className="filter-option-checkbox" key={organism.id}>
                        <input
                          type="checkbox"
                          id={organism.id}
                          name={organism.name}
                          checked={checkedOrganisms[organism.id] || false}
                          onChange={handleCheckboxChange(
                            checkedOrganisms,
                            organism.id
                          )}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <label
                          htmlFor={organism.id}
                          style={{
                            margin: "0px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {organism.name}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              {/* <div className="div-block-106">
                {Object.keys(publicData2).map((key) => {
                  return (
                    <div className="study-card" key={key}>
                      <div className="study-title">{key}</div>
                      <div className="tag-1">
                        <b>Study has {publicData2[key].length} files.</b>
                      </div>
                      <div className="study-desc">
                        <b>Instrument:</b> &nbsp; Illumina NovaSeq 6000 <br />
                        <b>Exp Title</b> &nbsp; GWS of mycobacterium
                        tuberculosis complex <br />
                      </div>
                    </div>
                  );
                })}
              </div> */}
              <div
                style={{
                  width: "90%",
                }}
              >
                <MaterialReactTable
                  columns={columns1}
                  data={publicData2 ? publicData2 : []}
                  enableSorting={true}
                  initialState={{
                    // density: "compact",
                    expanded: false, //expand all groups by default
                    pagination: { pageIndex: 0, pageSize: 10 },
                  }}
                  enableSelectAll={true}
                  // getRowId={(row) => row.id}
                  // muiTableBodyRowProps={({ row }) => ({
                  //   //implement row selection click events manually
                  //   onClick: () =>
                  //     setRowSelection((prev) => ({
                  //       ...prev,
                  //       [row.id]: !prev[row.id],
                  //     })),
                  //   selected: rowSelection[row.id],
                  //   sx: {
                  //     cursor: "pointer",
                  //   },
                  // })}
                  state={{ rowSelection, isLoading: tableLoading }}
                  enableRowSelection
                  onRowSelectionChange={setRowSelection}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: "pointer" },
                  })}

                  // Add other options as needed for sorting, pagination, etc.
                />
              </div>
            </div>

            {/* {publicData && publicData.length > 0 ? (
              <MaterialReactTable
                columns={inputColumns}
                data={publicData ? publicData : []}
                initialState={{
                  density: "compact",
                  expanded: false, //expand all groups by default
                  pagination: { pageIndex: 0, pageSize: 10 },
                }}
                enableSelectAll={true}
                // getRowId={(row) => row.id}
                // muiTableBodyRowProps={({ row }) => ({
                //   //implement row selection click events manually
                //   onClick: () =>
                //     setRowSelection((prev) => ({
                //       ...prev,
                //       [row.id]: !prev[row.id],
                //     })),
                //   selected: rowSelection[row.id],
                //   sx: {
                //     cursor: "pointer",
                //   },
                // })}
                state={{ rowSelection }}
              />
            ) : (
              ""
            )} */}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <div></div>
          <Button
            variant="secondary"
            style={{
              marginRight: "20px",
            }}
            onClick={handleClose}
          >
            Update Files
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublicDataExplorer;
