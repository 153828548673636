import React from "react";
import IGVConifg from "./igvConfig"; // Adjust the import path according to your file structure

function IGVBrowser() {
  const igvOptions = {
    genome: "hg19",
    tracks: [
      {
        name: "Skygenic-run",
        url: "https://storage.cloud.google.com/analysis-run-test/rnaseq_full_workflow/dbdad66f-c612-4842-9f7c-1b380fb86c60/call-star_alignment/rnaseq_star_alignment/03ef7375-3ea1-4177-97df-35a53a9825b6/call-picard_sort/shard-0/SRR13329769.Aligned.out.sorted.bam",
        indexURL: "https://storage.cloud.google.com/analysis-run-test/rnaseq_full_workflow/dbdad66f-c612-4842-9f7c-1b380fb86c60/call-star_alignment/rnaseq_star_alignment/03ef7375-3ea1-4177-97df-35a53a9825b6/call-picard_sort/shard-0/SRR13329769.Aligned.out.sorted.bam.bai",
        format: "bam",
        type: "alignment",
      },
    ],
  };

  return (
    <div className="App">
      <h1>IGV</h1>
      <IGVConifg options={igvOptions} />
    </div>
  );
}

export default IGVBrowser;
