import React from 'react'
import Divisions from './Divisions'
import DivisionUserTable from './DivisionUserTable'

const index = () => {
  return (
    <React.Fragment>
        <Divisions/>
        <DivisionUserTable/>
    </React.Fragment>
  )
}

export default index