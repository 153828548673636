import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Modal, CloseButton } from "react-bootstrap";
import { useForm } from "react-hook-form";
import api from "../../api/projects";
import {
  updateProject,
  inviteCollaborators,
  removeCollaborator,
} from "../../actions/projects";
// import profileImage from '../../assets/profile.jpg'
import Nav from "../header/Nav.component";
import Loader from "../loader/Loader.component";
import { Editor } from "@tinymce/tinymce-react";
import { Overlay, Tooltip } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify';

// const animatedComponents = makeAnimated();

const Summary = (props) => {
  const params = useParams();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.projects.currentUser);

  const [project, setProject] = useState(undefined);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editTitle, setEditTitle] = useState(false);

  //Tooltip
  const [showToolTip, setShowToolTip] = useState(false);
  const toolTipTarget = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm();

  useEffect(() => {
    // console.log(currentUser)

    async function fetchData() {
      const project_data = await api.get(`/projects/${params.id}`);
      if (project_data) {
        setProject(project_data.data);
        console.log('tttt', project_data.data);
      }
    }
    fetchData();
  }, [params.id]);

  const updateDescription = async () => {
    setProject({ ...project, overview: editorContent });

    dispatch(
      updateProject(params.id, {
        overview: editorContent,
      })
    );
    // dispatch(
    //   updateProject(params.id, {
    //     overview: project.overview.lastLevel?.content?.replaceAll(
    //       "<p><br></p>",
    //       ""
    //     ),
    //   })
    // );
    setEditDescription(false);
  };

  const updateTitle = async (name) => {
    dispatch(
      updateProject(params.id, {
        name: name,
      })
    );

    // dispatch(
    //   updateRocketChatChannelname(params.id, {
    //     name: name,
    //   })
    // );

    setEditTitle(false);
  };

  const handleProjectSettings = async (formValues) => {
    console.log("submitt");
    console.log(formValues);
    dispatch(
      updateProject(params.id, {
        name: formValues.name,
        compute_region: formValues.compute_region,
        preemtible: formValues.preemtible,
        rocket_chat_channel_id: project.rocket_chat_channel_id,
      })
    );
    setProject({
      ...project,
      name: formValues.name,
      compute_region: formValues.compute_region,
    });
    // console.log(this.state.collab_email)
    setShowSettingsModal(false);
    toast.success("Project settings updated")
  };

  // const renderProjectCategories = (categories) => {
  //   const { projectsCategories } = this.props;
  //   if (projectsCategories) {
  //     let options = projectsCategories.map((c) => ({
  //       value: c.value,
  //       label: c.name,
  //       _id: c._id,
  //     }));
  //     if (!categories) {
  //       return (
  //         <span className="relative-loader">
  //           <Loader />
  //         </span>
  //       );
  //     }
  //     return (
  //       <Select
  //         closeMenuOnSelect={false}
  //         closeMenuOnScroll={false}
  //         defaultValue={categories.map((c) => ({
  //           value: c.value,
  //           label: c.name,
  //           _id: c._id,
  //         }))}
  //         isMulti
  //         components={animatedComponents}
  //         options={options}
  //         onChange={this.handleInputChange}
  //       />
  //     );
  //   }
  // };

  // Add New Collaborators ---

  const handleAddCollaborator = async (formValues) => {
    console.log("submitt");
    console.log(formValues);

    dispatch(
      inviteCollaborators(params.id, {
        name: formValues.name,
        email: formValues.email,
        permission: formValues.permission,
        rocket_chat_channel_id: project.rocket_chat_channel_id,
      })
    );
    
    setProject({
      ...project,
      collaborators: [
        ...project.collaborators,
        {
          name: formValues.name,
          email: formValues.email,
          permission: formValues.permission,
        },
      ],
    });
    // console.log(this.state.collab_email)
    setShowCollabModal(false);
    toast.success("Collaborator invite sent")

  };

  const handleRemoveCollaborator = async (c) => {
    console.log("rmeove", c);
    dispatch(
      removeCollaborator(params.id, {
        email: c.email,
      })
    );

    let n_collabs = project.collaborators.filter((x) => x.email !== c.email);
    setProject({
      ...project,
      collaborators: n_collabs,
    });
    setShowCollabModal(false);
  };

  const [editorContent, setEditorContent] = useState("");


  return (
    <>
      <React.Fragment>
        <Nav project_name={project?.name}/>

        {!project ? (
          <div className="loader flex-center">
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <div className="content">
              <div className="column">
                <div className="boxed_section clear">
                  <div className="boxed_header">
                    <h3
                      style={{
                        fontSize: 24,
                      }}
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={(e) => {
                        console.log(e.currentTarget.textContent);
                        updateTitle(e.currentTarget.textContent);
                      }}
                    >
                      {project.name}
                    </h3>
                    <Link
                      to="#"
                      className="button interface"
                      onClick={() => setShowSettingsModal(true)}
                    >
                      Project Settings
                    </Link>
                  </div>
                  <form className="category flex">
                    <label htmlFor="category">Project Category</label>
                    <div className="select">
                      {/* {renderProjectCategories(project.category)} */}
                    </div>
                  </form>
                </div>

                <div
                  className="boxed_section"
                  onBlur={() => console.log("awdaw dwdawd")}
                >
                  <div className="boxed_header">
                    <h3>Description</h3>
                    {!editDescription ? (
                      <Link
                        to="#"
                        className="button"
                        onClick={() => setEditDescription(true)}
                      >
                        Edit
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>

                  {editDescription ? (
                    //  <ReactQuill
                    //   theme="snow"
                    //   value={project.overview}
                    //   onChange={(x) => setProject({ ...project, overview: x })}
                    //   onBlur={updateDescription}
                    // />
                    // <div style={{ width: 500, height: 300,
                    // display: !editDescription ? "none" : "" ,

                    // }}>
                    //   <div ref={quillRef} />
                    //   <div id="toolbar">
                    //     <select className="ql-size">
                    //       <option value="small" />
                    //       <option selected />
                    //       <option value="large" />
                    //       <option value="huge" />
                    //     </select>
                    //     <button className="ql-bold" />
                    //     <button className="ql-script" value="sub" />
                    //     <button className="ql-script" value="super" />
                    //   </div>
                    //   <div id="editor" />
                    // </div>

                    // <ReactQuill
                    //   theme="snow"
                    //   value={project.overview}
                    //   onChange={(x) => {
                    //     setProject({ ...project, overview: x });
                    //     console.log(x);
                    //   }}
                    //   suppressContentEditableWarning
                    //   onBlur={(x, source) => {
                    //     console.log("XDDD");
                    //     if (source === "silent") return; // ReactQuill paste issue

                    //     updateDescription();
                    //   }}
                    //   // onBlur={updateDescription}
                    //   // className="text-block-45"
                    //   readOnly={false}
                    // />
                    <>
                      <Editor
                        apiKey="ikvo3veoe3hy4ek9bvz5m9gljlmagaqhljvc7wayqj2k00e5"
                        toolbar="undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl"
                        onBlur={(x, source) => {
                          console.log("XDDD", source, x?.lastLevel?.content);
                          if (source === "silent") return; // ReactQuill paste issue

                          updateDescription();
                        }}
                        // onChange={(x) => {
                        //   setEditorContent(x?.lastLevel?.content);
                        //   console.log(x);
                        // }}
                        onEditorChange={(content, editor) => {
                          setEditorContent(content);
                          console.log(content);
                        }}
                        initialValue={project.overview}
                        init={{
                          height: 500,
                          menubar: true,
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                          ],
                          // menubar: "file edit view insert format tools table help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                      {/* <button onClick={log}>Log editor content</button> */}
                    </>
                  ) : (
                    <>
                      <br />
                      <p
                        dangerouslySetInnerHTML={{ __html: project.overview }}
                      />
                    </>
                  )}
                </div>

                {/* <div className="boxed_section">
                  <div className="boxed_header">
                    <h3>Title</h3>
                    {!editTitle ? (
                      <Link
                        to="#"
                        className="button"
                        onClick={() => setEditTitle(true)}
                      >
                        Edit
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  {editTitle ? (
                    <input
                      type="text"
                      defaultValue={project.name}
                      onChange={(x) =>
                        setProject({ ...project, name: x.target.value })
                      }
                      onBlur={updateTitle}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          updateTitle();
                        }
                      }}
                    />
                  ) : (
                    <p>{project.name}</p>
                  )}
                </div> */}
              </div>
              {/* <!-- end column --> */}

              {/* <!-- Settings Modal --> */}
              <React.Fragment>
                <Modal
                  show={showSettingsModal}
                  onHide={() => setShowSettingsModal(false)}
                  size="sm"
                >
                  <Modal.Header closeButton>
                    <h4>Project Settings</h4>
                  </Modal.Header>
                  <form onSubmit={handleSubmit(handleProjectSettings)}>
                    <Modal.Body>
                      <label>
                        Project Name
                        <input
                          className="form-control"
                          name="name"
                          id="name"
                          type="text"
                          defaultValue={project?.name}
                          {...register("name", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                      </label>
                      <label>
                        Compute Region
                        <select
                          name="compute_region"
                          {...register("compute_region", { required: true })}
                          id="compute_region"
                          defaultValue={project?.compute_region}
                        >
                          <option value="multi-region">Multi-Regional</option>
                          <option value="asia-east1">Taiwan</option>
                          <option value="asia-northeast1">Tokyo</option>
                          <option value="asia-south1">Mumbai</option>
                          <option value="asia-southeast1">Singapore</option>
                          <option value="australia-southeast1">Sydney</option>
                          <option value="europe-west1">Belgium</option>
                          <option value="europe-west2">London</option>
                          <option value="europe-west3">Frankfurt</option>
                          <option value="europe-west-4">Netherlands</option>
                          <option value="northamerica-northeast1">
                            Montréal
                          </option>
                          <option value="southamerica-east1">São Paulo</option>
                          <option value="us-central1">Iowa</option>
                          <option value="us-east4">Northern Virginia</option>
                          <option value="us-west1">Oregon</option>
                        </select>
                      </label>

                      <label onMouseLeave={() => setShowToolTip(false)}>
                        Preemptible Compute{" "}
                        <span
                          ref={toolTipTarget}
                          onMouseEnter={() => setShowToolTip(true)}
                        >
                          (i)
                        </span>
                        <Overlay
                          target={toolTipTarget.current}
                          show={showToolTip}
                          placement="right"
                        >
                          {(props) => (
                            <Tooltip
                              id="button-tooltip-2"
                              // className="tooltip-main"
                              {...props}
                            >
                              Preemptible VM instances are available at much
                              lower price—a 60-91% discount—compared to the
                              price of standard VMs. However, Compute Engine
                              might stop (preempt) these instances if it needs
                              to reclaim the compute capacity for allocation to
                              other VMs.{" "}
                              <a
                                href="https://cloud.google.com/compute/docs/instances/preemptible"
                                target="_blank"
                              >
                                Cick here for more info.
                              </a>
                            </Tooltip>
                          )}
                        </Overlay>
                        <select
                          name="preemptible"
                          {...register("preemptible", { required: true })}
                          defaultValue={project?.preemptible}
                        >
                          <option value="true">Enable</option>
                          <option value="false">Disable</option>
                        </select>
                      </label>
                      <label>
                        Billing
                        <select
                          name="billing"
                          {...register("billing", { required: true })}
                          defaultValue={project?.billing}
                        >
                          <option value="billing-A1">Billing A1</option>
                          <option value="billing A2">Billing A2</option>
                        </select>
                      </label>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        defaultValue="Save"
                        data-wait="Please wait..."
                      >
                        Save
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </React.Fragment>
              <div className="column">
                <div className="boxed_section">
                  <div className="boxed_header">
                    <h3>Collaborators</h3>
                    <Link
                      to="#"
                      onClick={() => setShowCollabModal(true)}
                      className="button interface"
                    >
                      Add
                    </Link>
                  </div>
                  <div className="collaborators">
                    {project.collaborators ? (
                      project.collaborators
                        .filter((c_users) => {
                          if (c_users.user_id !== currentUser.id && c_users.existing) {
                            return c_users;
                          }
                        })
                        .map((c) => {
                          return (
                            <div
                              className="collaborator"
                              key={c._id}
                              onClick={() => setShowCollabModal(true)}
                            >
                              <div className="avatar">
                                <em>
                                  {c?.name?.substring(0, 2).toUpperCase()}
                                </em>
                              </div>
                              <p>
                                <strong>
                                  <div>{c?.name?.toUpperCase()}</div>
                                </strong>
                                {c?.email?.toLowerCase()}
                              </p>
                            </div>
                          );
                        })
                    ) : (
                      <p>Project does not has any collaboratoes.</p>
                    )}
                  </div>
                </div>

                {/* <div className="boxed_section">
                  <div className="boxed_header">
                    <h3>Analysis Status</h3>
                    <Link to="/" className="button interface">
                      New
                    </Link>
                  </div>
                  <table id="myTable" className="display dataTable">
                    <thead>
                      <tr>
                        <th>Analytic Workflow</th>
                        <th>Version</th>
                        <th>Comments</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Link to="/" className="highlight">
                            RNA-seq Alignment
                          </Link>
                          <p>08-03-2020 at 6:15pm</p>
                        </td>
                        <td>V2</td>
                        <td>
                          <Link to="/">2</Link>
                        </td>
                        <td>
                          <Link to="/" className="button processing">
                            Processing
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link to="/" className="highlight">
                            Fusion Transcript Detection
                          </Link>
                          <p>08-03-2020 at 6:15pm</p>
                        </td>
                        <td>V2</td>
                        <td>
                          <Link to="/">2</Link>
                        </td>
                        <td>
                          <Link to="/" className="button error">
                            Error
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link to="/" className="highlight">
                            My SkyGenic Project
                          </Link>
                          <p>08-03-2020 at 6:15pm</p>
                        </td>
                        <td>V2</td>
                        <td>
                          <Link to="/">2</Link>
                        </td>
                        <td>
                          <Link to="/" className="button">
                            complete
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
              </div>
            </div>
            {showCollabModal && (
              <Modal
                show={showCollabModal}
                onHide={() => setShowCollabModal(false)}
                id="add_collaborator"
                size="lg"
                style={{
                  width: "100%",
                  maxWidth: "870px",
                  transform: "translate(-50%, 10%)",
                  left: "50%",
                  // position: "absolute"
                }}
              >
                <Modal.Header closeButton>
                  <h4>Add Collaborators</h4>
                </Modal.Header>
                <form
                  onSubmit={handleSubmit2(handleAddCollaborator)}
                  id="add_collaborator"
                >
                  <Modal.Body>
                    <div
                      style={{
                        display: "-webkit-inline-box",
                        margin: "0px 0px 10px -2px",
                      }}
                    >
                      <input
                        // className="form-control"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Name"
                        style={{ width: "auto", margin: "4px" }}
                        {...register2("name", {
                          required: true,
                          maxLength: 80,
                        })}
                      />

                      <input
                        // className="form-control"
                        name="email"
                        id="email"
                        type="text"
                        placeholder="Email"
                        style={{ width: "300px", margin: "4px" }}
                        {...register2("email", {
                          required: true,
                          maxLength: 80,
                        })}
                      />
                      <select
                        name="permission"
                        defaultValue={"Admin"}
                        style={{ width: "280px", margin: "4px 0px 4px 4px" }}
                        {...register2("permission", { required: true })}
                      >
                        <option value="Admin">Admin</option>
                        <option value="Viewer">Viewer</option>
                        <option value="Editor">Editor</option>
                      </select>
                    </div>
                    <br />
                    <button
                      className="btn btn-primary"
                      type="submit"
                      defaultValue="Collab"
                      data-wait="Please wait..."
                      // onClick={() => handleSubmit2(handleAddCollaborator)}
                    >
                      Add Collaborator
                    </button>
                    <br />
                    <h5>Existing Shares</h5>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {console.log(project.collaborators)}
                      {project.collaborators
                        ? project.collaborators
                            .filter((x) => x.existing === true)
                            .map((c) => {
                              return (
                                <div
                                  key={c.email}
                                  style={{
                                    display: "-webkit-inline-box",
                                    margin: "0px 0px 10px -2px",
                                  }}
                                >
                                  <input
                                    name="email"
                                    id="email"
                                    type="text"
                                    placeholder="Name"
                                    style={{ width: "auto", margin: "4px" }}
                                    value={c?.name}
                                    disabled
                                  />

                                  <input
                                    // className="form-control"
                                    name="email"
                                    id="email"
                                    type="text"
                                    placeholder="Email"
                                    style={{ width: "300px", margin: "4px" }}
                                    value={c?.email}
                                    disabled
                                  />

                                  <select
                                    name="permission"
                                    style={{
                                      width: "280px",
                                      margin: "4px 0px 4px 4px",
                                    }}
                                    key={c.email}
                                    disabled
                                    // {...register2("permission", {
                                    //   required: true,
                                    // })}
                                  >
                                    <option
                                      value="Admin"
                                      selected={"Admin" === c?.permission}
                                    >
                                      Admin
                                    </option>
                                    <option
                                      value="Viewer"
                                      selected={"Viewer" === c?.permission}
                                    >
                                      Viewer
                                    </option>
                                    <option
                                      value="Editor"
                                      selected={"Editor" === c?.permission}
                                    >
                                      Editor
                                    </option>
                                  </select>
                                  <button
                                    style={{
                                      margin: "10px 10px",
                                      padding: "4px 5px",
                                    }}
                                    onClick={() => handleRemoveCollaborator(c)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </div>
                              );
                            })
                        : ""}
                    </div>
                    <br />
                    {/* <button
                      className="btn btn-primary"
                      // type="submit"
                      defaultValue="Login"
                      data-wait="Please wait..."
                    >
                      Update Permission
                    </button> */}
                    {/* <br /> */}
                    <h5>Collaborators Without Skygenic Account</h5>
                    <br />
                    {project.collaborators
                      ? project.collaborators
                          .filter(
                            (x) => x.existing === false && x.role !== "Owner"
                          )
                          .map((c) => {
                            return (
                              <div
                                key={c.email}
                                style={{
                                  display: "-webkit-inline-box",
                                  margin: "0px 0px 10px -2px",
                                }}
                              >
                                <input
                                  name="email"
                                  id="email"
                                  type="text"
                                  placeholder="Name"
                                  style={{ width: "auto", margin: "4px" }}
                                  value={c?.name}
                                  disabled
                                />

                                <input
                                  // className="form-control"
                                  name="email"
                                  id="email"
                                  type="text"
                                  placeholder="Email"
                                  style={{ width: "300px", margin: "4px" }}
                                  value={c?.email}
                                  disabled
                                />

                                <select
                                  name="permission"
                                  style={{
                                    width: "280px",
                                    margin: "4px 0px 4px 4px",
                                  }}
                                  key={c.email}
                                  disabled
                                  // {...register2("permission", {
                                  //   required: true,
                                  // })}
                                >
                                  <option
                                    value="Admin"
                                    selected={"Admin" === c?.permission}
                                  >
                                    Admin
                                  </option>
                                  <option
                                    value="Viewer"
                                    selected={"Viewer" === c?.permission}
                                  >
                                    Viewer
                                  </option>
                                  <option
                                    value="Editor"
                                    selected={"Editor" === c?.permission}
                                  >
                                    Editor
                                  </option>
                                </select>
                                <button
                                  style={{
                                    margin: "10px 10px",
                                    padding: "4px 5px",
                                  }}
                                  onClick={() => handleRemoveCollaborator(c)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>
                            );
                          })
                      : ""}
                    {/* <button
                      className="btn btn-primary"
                      // type="submit"
                      defaultValue="Login"
                      data-wait="Please wait..."
                    >
                      Collaborators Without Skygenic Account
                    </button> */}
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </form>
              </Modal>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </>
  );
};

export default Summary;
