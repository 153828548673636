import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { cloneDeep, omit } from "lodash";
import { Modal } from "react-bootstrap";
import Tags from "../../components/Tag/Tags.component";
import { useEffect } from "react";

function ExpDesignModal({
  inputFileOut,
  open,
  setOpenExpDesignModal,
  setExpGenData,
}) {


  const [variables, setVariables] = useState([]);
  const [sampleInputFiles, setSampleInputFiles] = useState([]);

  const columnsFromBackend = {
    UNTAGGED: {
      name: "UNTAGGED",
      items: sampleInputFiles ? sampleInputFiles : [],
    },
  };
  const [dimensions, setDimensions] = useState(columnsFromBackend);
  const [hoverColumn, setHoverColumn] = useState(null);

  const [selectedVariable, setSelectedVariable] = useState(0);
  const [multiDrag, setMultiDrag] = useState(false);
  // const [newVariableModalOpen, setNewVariableModalOpen] = useState(false);
  // const [newDimensionModalOpen, setNewDimensionModalOpen] = useState(false);

  // const addDimension = () => {
  //   console.log(dimensions);
  //   let c_c = cloneDeep(dimensions);
  //   c_c["New Dimension"] = {
  //     name: "New Dimension",
  //     items: [],
  //   };
  //   setDimensions(c_c);
  // };



  const addDimension = () => {
    if (variables.length === 0) {
      alert("Please add a variable first");
      return;
    }

    let c_c = cloneDeep(variables);
    let n_dim = Object.keys(c_c[selectedVariable].columns).length;
    const n_name = (Math.random() + 1).toString(36).substring(7);
    // add new dimension to the end of object with key & name as n_name, in variables state
    c_c[selectedVariable].columns[n_name] = {
      name: `New Dimension ${n_dim + 1}`,
      items: [],
    };
    setVariables(c_c);
  };

  const deleteDimension = (item) => {
    // Delete the dimension with index item, in variables state
    let c_c = cloneDeep(variables);

    c_c[selectedVariable].columns = omit(c_c[selectedVariable].columns, item);
    console.log(
      "selectedVariable",
      selectedVariable,
      c_c[selectedVariable].columns
    );
    setVariables(c_c);
  };

  const addNewVariable = () => {
    // Add a new variable with name "New Variable", in variables state
    let c_c = cloneDeep(variables);
    let n_var = variables.length;
    c_c.push({
      name: `New Variable ${n_var + 1}`,
      columns: columnsFromBackend,
    });
    setVariables(c_c);
  };

  const deleteVariable = (item) => {
    // Delete the variable with index item, in variables state
    let c_c = cloneDeep(variables);
    c_c.splice(item, 1);
    setVariables(c_c);
    setSelectedVariable(item - 1);
  };

  useEffect(() => {

    if(!inputFileOut) return;
    console.log("inputFileOut", inputFileOut)
    // array of unique paired files out, so ex. [{file_name: "SRR13329771"}, {file_name: "SRR13329772"}, {file_name: "SRR13329773"}]
    let sample_list = [];
    inputFileOut && inputFileOut.forEach(file => {
        if(file.paired && file.read_num !== "2"){
          sample_list.push({
            file_name: file.paired,
            _id: file._id
          })
        }
    });

    console.log("sample_list", sample_list)
    setSampleInputFiles(sample_list)
  },[inputFileOut])

  useEffect(() => {
    setDimensions(variables[selectedVariable]?.columns);
  }, [selectedVariable, sampleInputFiles, variables]);

  useEffect(() => {
    if(variables.length === 0 && sampleInputFiles.length > 0){
      addNewVariable()
    }
  }, [variables, sampleInputFiles]);

  useEffect(() => {
    // set updated dimensions to valriables state
    let c_c = cloneDeep(variables);
    if (c_c.length > 0 && c_c[selectedVariable]) {
      c_c[selectedVariable].columns = dimensions;
      setVariables(c_c);
    }
  }, [dimensions]);

  const onDragEnd = (result) => {
    console.log(result, dimensions);
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = dimensions[source.droppableId];
      const destColumn = dimensions[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      if (multiDrag) {
        setDimensions({
          ...dimensions,
          [source.droppableId]: {
            ...sourceColumn,
            items: [],
          },
          [destination.droppableId]: {
            ...destColumn,
            items: [...destItems, ...sourceItems],
          },
        });
      } else {
        setDimensions({
          ...dimensions,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        });
      }
    } else {
      const column = dimensions[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setDimensions({
        ...dimensions,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
    // ------

    // const c_c = cloneDeep(variables);
    // c_c[selectedVariable].columns = columns;
    // setVariables(c_c);

    // let updatedDimensionName =

    // const updatedColumne =
    // c_c[selectedVariable].columns = updatedColumne;
    // setVariables(c_c);
  };

  const handleCloseExpModal = () => {
    setOpenExpDesignModal(false);

    // setExpGenData(variables)

    // array for all variables
    let allVariables = variables.map((variable) => {
      return variable.name.split(".fastq")[0];
    });
    console.log("allVariables", allVariables);

    const eachFileData = sampleInputFiles.map((file) => {
      const fd_ = [];
      variables.map((variable) => {
        console.log("v---", variable);

        for (const property in variable.columns) {
          if (property === "UNTAGGED") {
            continue;
          }

          variable.columns[property].items.map((itm) => {
            if (itm.file_name === file.file_name) {
              fd_.push(variable.columns[property].name);
            }
          });
        }

        // console.log(file.file_name, fd_)
      });
      return {
        fileName: file.file_name,
        fileData: fd_,
      };
    });

    console.log("eachFileData", eachFileData);

    var tsv_data = "";
    tsv_data = "sample_row" + "\t" + allVariables.join("\t") + "\n";
    eachFileData.map((file) => {
      tsv_data += file.fileName + "\t" + file.fileData.join("\t") + "\n";
    });
    setExpGenData(tsv_data);
  };

  return (
    <Modal
      show={open}
      onHide={handleCloseExpModal}
      // backdrop="static"
      keyboard={true}
      // fullscreen={true}
      centered
      // size="lg"
      dialogClassName="div-block-12"
      // contentClassName="div-block-12"
    >
      {/* <Tags id={params.id}/>      */}

      <div
        className="div-block-68"
        style={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <div className="div-block-13">
          <div className="div-block-25" onClick={() => handleCloseExpModal()}>
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60fc3a2f21621b84f7932381_icons8-back-96.png"
              loading="lazy"
              width={16}
              alt=""
            />
            <div className="text-block-3">Back</div>
          </div>
          <div className="text-block-2">Experimental Design</div>
          <div />
        </div>
        <div
          className="div-block-70"
          style={{
            height: "80%",
          }}
        >
          <div className="div-block-71">
            {variables?.map((x, i) => {
              return (
                <div
                  className="div-block-72"
                  key={i}
                  style={{
                    backgroundColor: selectedVariable === i ? "#d0d2db" : "",
                  }}
                  onClick={() => setSelectedVariable(i)}
                >
                  <div
                    className="text-block-44"
                    contentEditable
                    suppressContentEditableWarning={true}
                    onBlur={(e) => {
                      //edit variable name
                      // let c_v = cloneDeep(variable);
                      // c_v[i] = e.target.innerText;
                      // setVariables(c_v);
                      // console.log(c_v);
                      let c_c = cloneDeep(variables);
                      c_c[i].name = e.target.innerText;
                      setVariables(c_c);
                    }}
                  >
                    {x.name}
                  </div>
                  <img
                    src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/61a4d819dc4a9c68b90806e6_icons8-close-cross-symbol-for-discontinued-and-invalid-48.png"
                    loading="lazy"
                    width={13}
                    alt=""
                    className="image-5"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteVariable(i);
                    }}
                    // onClick={() => {
                    //   //delete variable
                    //   setVariables(variables.filter((y) => y !== x));
                    // }}
                  />
                </div>
              );
            })}

            <div className="div-block-74" onClick={() => addNewVariable()}>
              <img
                src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/616fe7636a6de343e228cef7_icons8-add-64.png"
                loading="lazy"
                width={22}
                alt=""
              />
            </div>
          </div>
          <div className="exp-menu-bar">
            <input
              className="exp-filter"
              placeholder="Filter"
              onChange={(e) => {
                // filter column items, if value is "" then set UNTAGGED column items to inputFileOut
                // if (e.target.value === "") {
                //   setMultiDrag(false);
                //   let c_d = cloneDeep(dimensions);
                //   c_d[columnId].items = inputFileOut;
                //   setDimensions(c_d);
                // } else {
                //   setMultiDrag(true);
                let c_d = cloneDeep(dimensions);
                c_d["UNTAGGED"].items = sampleInputFiles?.filter((item) =>
                  item.file_name.includes(e.target.value)
                );
                setDimensions(c_d);
                console.log(c_d);
                // }
              }}
            ></input>
            <button
            className="exp-multi-drag-btn"
              style={{
                width: "120px",
                height: "30px",
                marginBottom: "4px",

                // add this conditionally to multidrag - color: #3b3b53; background-color: #dae3ff; border: 1px solid #8088e0;
                color: multiDrag ? "#3b3b53" : "",
                border: multiDrag ? "1px solid #8088e0" : "",
                backgroundColor: multiDrag ? "#dae3ff" : "#ffffff",

              }}
              onClick={() => setMultiDrag(!multiDrag)}
            >
              Multi Drag - {multiDrag ? "ON" : "OFF"}
            </button>
          </div>

          <div className="div-block-73">
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              {dimensions &&
                Object.entries(dimensions).map(([columnId, column], index) => {
                  return (
                    <div
                      div
                      className="div-block-75"
                      // style={{
                      //   display: "flex",
                      //   flexDirection: "column",
                      //   alignItems: "center",
                      // }}
                      key={columnId}
                      onMouseEnter={() => setHoverColumn(columnId)}
                      onMouseLeave={() => setHoverColumn(null)}
                    >
                      <div className="div-block-76">
                        <div
                          className="text-block-43"
                          contentEditable={
                            column.name === "UNTAGGED" ? false : true
                          }
                          suppressContentEditableWarning={true}
                          // onInput={e => console.log('Text inside div', e.currentTarget.textContent)}
                          onBlur={(e) => {
                            //edit dimension key value and name to be innerText in variables state
                            let c_c = cloneDeep(variables);
                            c_c[selectedVariable].columns[columnId].name =
                              e.target.innerText;
                            setVariables(c_c);
                          }}
                        >
                          {" "}
                          {column.name}
                        </div>
                        {hoverColumn === columnId && (
                          <img
                            src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/61a4d819dc4a9c68b90806e6_icons8-close-cross-symbol-for-discontinued-and-invalid-48.png"
                            loading="lazy"
                            width={22}
                            alt=""
                            className="image-4"
                            onClick={() => deleteDimension(columnId)}
                            style={{
                              display: column.name === "UNTAGGED" ? "none" : "",
                            }}
                          />
                        )}
                      </div>
                      <Droppable
                        droppableId={columnId}
                        key={columnId}
                        // style={{
                        //   width: "100%"
                        // }}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                width: "100%",
                                height: "100%",
                                background: snapshot.isDraggingOver
                                  ? "lightblue"
                                  : "#00000000",
                                padding: "10px",
                                overflow: "hidden",
                              }}
                            >
                              {column?.items?.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            padding: 16,
                                            margin: "0 0 8px 0",
                                            minHeight: "50px",
                                            backgroundColor: snapshot.isDragging
                                              ? "#7cb5c054"
                                              : "#fff",
                                            color: "black",
                                            ...provided.draggableProps.style,
                                          }}
                                          className={"div-block-77"}
                                        >
                                          <div className="text-block-44">
                                          {item.file_name}
                                          </div>
                                          {/* {item.content} */}
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  );
                })}
            </DragDropContext>
            <button className="div-block-74" onClick={() => addDimension()}>
              Add
            </button>
          </div>
        </div>
        <div className="div-block-69" />
      </div>
    </Modal>
  );
}

export default ExpDesignModal;
