import React, { useState, useEffect } from "react";
import { HashRouter, Switch } from "react-router-dom";
import routes from "../../routes";
import "./App.css";
import Header from "../header/Header.component";
// import { useIdleTimer } from 'react-idle-timer'
import Cookies from "js-cookie";
import RocketChat from "../../services/Rocketchat";
import { faComment, faMessages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useInactivityTimeout from "../../utils/useInactivityTimeout";
import { ToastContainer, toast } from 'react-toastify';


const App = () => {
  const [chatActive, setChatActive] = useState(false);

  // Session timout func
  const timeoutDuration = 15 * 60 * 1000; // 5 minutes
  const removeSessionIdCookie = () => {
    console.log("removing cookie");
    Cookies.remove("SessionID");
    window.location = `https://drive.skygenic.com/#!/login`;
  };

  useInactivityTimeout(timeoutDuration, removeSessionIdCookie);

  return (
    <HashRouter>
      <Header />
      <ToastContainer />
      <Switch>{routes}</Switch>
      
      <div
        style={{
          // round div floating bottom right corner
          position: "fixed",
          bottom: "2.5%",
          right: "1%",
          width: "55px",
          height: "55px",
          borderRadius: "50%",
          // add white gradient background
          background:
            "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%)",
          display: "none", // flex <---
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 0 10px #bcc8dc",
          cursor: "pointer",
        }}
        onClick={() => setChatActive(!chatActive)}
      >
        <FontAwesomeIcon
          icon={faComment}
          style={{ color: "#51ABFE" }}
          size="2x"
        />
      </div>
      <div
        style={{
          // display if chat is active
          display: chatActive ? "block" : "none",
        }}
      >
        <RocketChat />
      </div>
    </HashRouter>
  );
};

export default App;
