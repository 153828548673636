import { useEffect, useRef } from 'react';

const useInactivityTimeout = (timeoutDuration, onTimeout) => {
  const timeoutIdRef = useRef(null);

  const resetTimeout = () => {
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(onTimeout, timeoutDuration);
  };

  useEffect(() => {
    resetTimeout();

    const handleActivity = () => resetTimeout();

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);

    return () => {
      clearTimeout(timeoutIdRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [timeoutDuration, onTimeout]); // Only re-run if timeoutDuration or onTimeout changes

  return resetTimeout;
};

export default useInactivityTimeout;
