const { ADD_PROJECT, LIST_PROJECTS, LIST_PROJECT, UPDATE_PROJECT, GET_CATEGORIES, LIST_PROJECT_FILES, ADD_PROJECT_FILES, CURRENT_USER, LIST_COLLABORATORS_FILES, REPORTS, GET_REPORTS, ADD_REPORT } = require("../actions/types");
const roles = require('../actions/roles')
const projectReducer = (state = {}, action) => {
  switch (action.type) {
    case LIST_PROJECTS:
      return { ...state, userProjects: action.payload }

    case CURRENT_USER:
      return { ...state, currentUser: action.payload }

    case ADD_REPORT:
      return { ...state, reports: [...state.reports, action.payload] }

    case GET_REPORTS:
      return { ...state, reports: action.reports }

    case ADD_PROJECT:
      console.log(state.userProjects)
      return {...state, userProjects: [...state.userProjects, action.payload]}

    case LIST_PROJECT: {
      let project = action.payload
      if (project.collaborators) {
        project.collaborators = project.collaborators.map(collaborator => {
          collaborator.role.name = roles[[collaborator.role.role]]
          return collaborator
        })
      }
      return { ...state, project: action.payload }
    }

    case UPDATE_PROJECT:
      const [key] = Object.keys(action.payload)
      const project = state.project
      project[[key]] = action.payload[[key]]
      return { ...state, project }

    case LIST_COLLABORATORS_FILES:
      return { ...state, collaboratorsFiles: action.payload }

    case LIST_PROJECT_FILES: case ADD_PROJECT_FILES:
      console.log(action.payload)
      let projectFiles = action.payload
      // or null in projectFiles array
      if(projectFiles.includes(null)){
        alert("Project outdated, recreate it.")
        // redirect from current "http://localhost:3001/#/647f31bc7e2c676273822daa/files" to "http://localhost:3001/#/647f31bc7e2c676273822daa/output"
        window.location.href = window
          .location
          .href
          .replace('/files', '/output')
      }
      if(!projectFiles){
        return { ...state, projectFiles: [] }

      } 
      
      projectFiles.files = projectFiles?.map(file => {
        file.tags = []
        if (file.file_tags && file.file_tags.length) {
          let [tags] = file.file_tags
          file.tags = Object.keys(tags).map(tag => tags[[tag]]).flat() || []
        }
        // file.file_size = file.file_size < (1024 * 1024) ? file.file_size / 1024 + ' KB' : file.file_size / (1024 * 1024) + ' MB'
        return file
      })
      return { ...state, projectFiles: projectFiles }

    case GET_CATEGORIES:
      return { ...state, projectsCategories: action.payload.categories }
    default:
      return state
  }
}

export default projectReducer