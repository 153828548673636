import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { updateSingleUserAdminUsers } from "../../../actions/userAdmin/actionCreators";
import { useHistory, useLocation } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import closeCircle from '../../../assets/Circle-X-close.png';
import uparrow from "../../../assets/Chevron-Up.png";
import downarrow from "../../../assets/Chevron-Down.png";

const OutsideAlerter = ({ children, setOpen }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  return <div ref={wrapperRef}>{children}</div>;
};

function Updateuser() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const divisionData = useSelector((state) => state.userAdmin.division);
  const singleUser = useSelector(
    (state) => state.userAdmin.singleUserAdminuser
  );
  const currentUser = useSelector((state) => state.projects.currentUser);

  const initialFormValues = useMemo(() => ({
    _id: location.state?._id,
    username: singleUser[0]?.username,
    nickname: singleUser[0]?.nickname,
    first_name: singleUser[0]?.first_name,
    last_name: singleUser[0]?.last_name,
    email: singleUser[0]?.email,
    roles: singleUser[0]?.roles,
    updated_by: currentUser?.id,
    created_by: singleUser[0]?.created_by,
    updated_at: "",
    is_disabled: singleUser[0]?.is_disabled,
    organisation_id: singleUser[0]?.organisation_id,
  }), [currentUser, location.state, singleUser]);

  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState({});
  const [divisions, setDivisions] = useState([]);
  const [open, setOpen] = useState(false);
  const [userRole,setUserRole] = useState(singleUser[0]?.roles?singleUser[0]?.roles:"");

  useEffect(() => {
    let result = divisionData.filter((item) => {
      return singleUser[0]?.divisions.some(
        (item2) => item2.divisionId === item._id
      );
    });

    const div = result.map((item) => ({
      value: item._id,
      label: item.division_name,
    }));

    setDivisions(div);
  }, [divisionData, singleUser]);

  const handlechange = useCallback((e, field) => {
    const { name, value } = e.target;
    let companyDivisions = [];
    if (field === "roles" && value === "company admin") {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      setUserRole(value);
      divisionData.forEach((item) => {
        let obj = {
          value: item._id,
          label: item.division_name,
        };
        companyDivisions.push(obj);
      });
      setDivisions(companyDivisions);
    } else if (field === "roles" && value === "division admin") {
      setDivisions([]);
      setUserRole(value);
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    } else if (field === "roles" && (value === "member" || value === "view only")) {
      setDivisions([]);
      setUserRole(value);
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  }, [divisionData]);

  const validateForm = useCallback(() => {
    const errors = {};
    const ref = /^[a-zA-Z0-9]+$/;
    const eml = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // Add other validation rules here
    if (!formValues.email) {
      errors.email = "Field is empty";
    } else if (!eml.test(formValues.email)) {
      errors.email = "Enter valid Email";
    }
    if (!formValues.first_name) {
      errors.first_name = "Field is empty";
    } else if (!ref.test(formValues.first_name)) {
      errors.first_name = 'No special character';
    }
    if (!formValues.last_name) {
      errors.last_name = "Field is empty";
    } else if (!ref.test(formValues.last_name)) {
      errors.last_name = 'No special character';
    }
    if (!formValues.roles) {
      errors.roles = "Field is empty";
    }
    if (divisions.map(div => ({ divisionId: div.value })).length === 0) {
      errors.division_id = "Field is empty";
    }
    if (!formValues.organisation_id) {
      errors.organisation_id = "Field is empty";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [divisions, formValues]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let divisionList = divisions.map((division) => ({
      divisionId: division.value,
    }));
    let formData = {
      ...formValues,
      divisions: divisionList,
      username: formValues.email,
      nickname: formValues.first_name + " " + formValues.last_name,
      updated_at: Math.floor(Date.now() / 1000),
    };

    if (validateForm()) {

      dispatch(updateSingleUserAdminUsers(formData))
        .then(() => {
          history.push("/admin/users/administration");
          setFormValues(initialFormValues);
        })
        .catch((error) => {
          console.error('Error adding user:', error);
        });
    }
  };

  const handleBack = useCallback((e) => {
    e.preventDefault();
    history.goBack();
  }, [history]);

  const options = useMemo(() => {
    return divisionData.map((item) => ({
      value: item._id,
      label: item.division_name,
    }));
  }, [divisionData]);

  const closeDropDown = useCallback(() => {
    setDivisions([]);
    setOpen(false);
  }, []);

  const handleCheckboxChange = useCallback((value) => {
    if (userRole === 'member' || userRole === 'view only') {
      setDivisions([{ value, label: value }]);
    } else {
      if (divisions.find((val) => val.value === value)) {
        let filterOption = divisions.filter((val) => val.value !== value);
        setDivisions(filterOption);
      } else {
        let obj = {
          value: value,
          label: value // You can set the label based on your requirements
        }
        setDivisions([...divisions, obj]);
      }
    }
  }, [divisions,userRole]);

  return (
    <div className="mod">
      <Modal.Body className="">
        <div className="pull"> </div>
        <h4 className="text-center">UPDATE USER</h4>
        <form>
          <div className="row m-3">
            <div className="col ">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={handlechange}
                name="first_name"
                value={formValues.first_name}
              />
              <span className="text-danger"> {formErrors.first_name} </span>
            </div>
            <div className="col">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                onChange={handlechange}
                name="last_name"
                value={formValues.last_name}
              />
              <span className="text-danger">{formErrors.last_name} </span>
            </div>
          </div>
          <div className="row m-3">
            <div className="col">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                onChange={handlechange}
                name="email"
                value={formValues.email}
              />
              <span className="text-danger">{formErrors.email} </span>
            </div>
            <div className="col add-user-select">
              <label htmlFor="roles">Role</label>
              <select
                name="roles"
                value={formValues.roles}
                onChange={(e) => handlechange(e, "roles")}
              >
                <option value="company admin">Company admin</option>
                <option value="division admin">Division admin</option>
                <option value="member">Member</option>
                <option value="view only">View only</option>
              </select>
              <span className="text-danger"> {formErrors.roles} </span>
            </div>
          </div>
          <div className="row m-3 d-flex">
            <div className="col add-user-select">
              <div className="multi-select-dropdown">
                <label htmlFor="division_id">Division Name</label>
                <OutsideAlerter setOpen={setOpen}>
                  <div className="multi-select-dropdown_wrapper">
                    <div className='multi-select-dropdown-input' onClick={() => setOpen((prev) => !prev)}>
                      {divisions.length ? `${divisions.length} Division${divisions.length > 1 ? 's' : ''} selected` : "Division Name"}
                    </div>
                    <div className='multi-select-dropdown-direction'>
                      <img src={closeCircle} height={18} onClick={closeDropDown} alt='closeIcon' />
                      {open ? <img src={downarrow} height={18} onClick={() => setOpen(false)} alt='downIcon' /> : <img src={uparrow} height={18} onClick={() => setOpen(true)} alt='upIcon' />}
                    </div>
                  </div>
                  <div className="multi-select-dropdown_options_wrapper" style={{ display: open ? "block" : "none" }}>
                    <ul className="multi-select-dropdown_options">
                      {options.map(option => {
                        return (
                          <li className="multi-select-dropdown_option" key={option.value} onClick={() => handleCheckboxChange(option.value)}>
                           {(userRole === 'member' || userRole === 'view only') ?
                              <input
                                type="radio"
                                name="options"
                                value={option.value}
                                checked={divisions.find((val) => val.value === option.value)}
                                onChange={() => { }}
                                className="c-multi-select-dropdown_option-checkbox"></input>
                              : <input type="checkbox"
                                value={option.value}
                                checked={divisions.find((val) => val.value === option.value)}
                                onChange={() => { }}
                                className="c-multi-select-dropdown_option-checkbox"></input>}
                            <span>{option.label}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </OutsideAlerter>
                <span className="text-danger" style={{ fontSize: "14px", fontWeight: "400" }}>{formErrors.division_id} </span>
              </div>
            </div>
            <div className="col-6 ">
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={handleSubmit}
              className="add-btn btn btn-primary ia-btn m-3"
            >
              Update User
            </button>
            <button
              onClick={handleBack}
              className="add-btn btn btn-primary ia-btn m-3"
            >
              Back
            </button>
          </div>
        </form>
      </Modal.Body>
      {/* <ToastContainer autoClose={7000} theme="light"/> */}
    </div>
  );
}
export default Updateuser;
