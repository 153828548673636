import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import auth from  '../../services/AuthService'
import Loader from "../loader/Loader.component";
import { useDispatch } from "react-redux";


const VerificationComponent = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  
  const location =  window.location.href;
  const findIndex = location.indexOf("#");
  const url = location.slice(findIndex+1);

  console.log("findindex and URL", findIndex, url);

  useEffect(() => {

    // setLoading(false)

    if (!auth.isLoggedIn()) {
      const token = auth.verify()
      token.then((res) => {
        console.log("TOKEN:::",res)
        if(res){
          setLoading(false)
          dispatch({ type: "CURRENT_USER", payload: res});
          return <Redirect to='/' />
        } else {
          console.log("token not found")
          window.location = `https://drive.skygenic.com/#!/login`
          // window.location = `https://drive.skygenic.com/#!/login?returnUrl=${window.location.href}`
        }
      })
    } 
  }, [])
  return (
    <div>
      {
        loading ? <div className="loader flex-center"><Loader /></div>: <Redirect to={url} />
      }
    </div>
  )
}


export default VerificationComponent;

