import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Nav from '../header/Nav.component';
import Loader from '../loader/Loader.component';
import ExperimentItem from './ExperimentItem.component';
import { getExpirment } from '../../actions/projects/experiment';
import AddExperiment from '../modals/AddExperiment.modal'



class Tags extends Component {
  constructor(props) {
    super(props);
    this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
    this.state = {
      visibility: false,
      activeTab: 0,
      showAddExpModal: false,
      // projectId: '5ca66ef199d97920eb49aeb2'
      projectId: this.props.id
    }
  }
  async componentDidMount() {
    await this.props.getExpirment(this.state.projectId)
  }

  handleToggleVisibility() {
    this.setState((prevState) => {
      return {
        visibility: !prevState.visibility,
      }
    })
  }

  activateTab = (index) => {
    this.setState(prev => ({
      activeTab: prev.activeTab === index ? -1 : index
    }));
  }
  handleExperimentModalClose = () => {
    this.setState({
      showAddExpModal: false
    })
  }
  handleAddExperiment = () => {
    this.setState({
      showAddExpModal: true
    })
  }
  renderAddExpLink() {
    return !this.props.experiment.length ? <Link to="#" className='button' onClick={this.handleAddExperiment}>Add Experiment</Link>
      : <React.Fragment></React.Fragment>
  }
  render() {

    const { activeTab } = this.state;
    return (
      <React.Fragment>
        {/* <Nav active={3} /> */}
        {!this.props.experiment ?
          <div className="loader flex-center"><Loader /></div>
          :
          <React.Fragment>
            <div className="content">
              <div className="boxed_section">
                <div className="boxed_header">
                  <h3>Experimental Design</h3>
                  {this.renderAddExpLink()}
                </div>
                {this.state.showAddExpModal ?
                  <AddExperiment
                    projectId={this.state.projectId}
                    showModal={this.state.showAddExpModal}
                    handleClose={this.handleExperimentModalClose}
                  />
                  :
                  <React.Fragment></React.Fragment>
                }

                <div className='accordion' role='tablist'>
                  {this.props.experiment.map((exp, index) =>
                    <ExperimentItem
                      key={exp.variables.length}
                      activeTab={activeTab}
                      index={index}
                      {...exp}
                      activateTab={() => this.activateTab(index)}
                    />
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  experiment: state.experiment.experiment
})

export default connect(
  mapStateToProps, { getExpirment }
)(Tags);
