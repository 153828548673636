import React, { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import axios from "axios";

const HeatmapTable = ({ rawData }) => {
  // Extract column headers from the first row
  const headers = rawData[0];

  // Define columns dynamically based on headers
  const columns = headers.map((header) => ({
    accessorKey: header,
    header,
  }));

  // Transform the rest of the data into an array of objects
  const rows = rawData.slice(1).map((row) => {
    // const values = row.split(',');
    return headers.reduce((obj, header, index) => {
      obj[header] = row[index];
      return obj;
    }, {});
  });

  return (
    <MaterialReactTable
      title="Contact List"
      columns={columns}
      data={rows ? rows : []}
      paginationPerPage={15}
      pagination
      // set density
      density="compact"
      // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
      // subHeader
      // subHeaderComponent={subHeaderComponentMemo}
      // selectableRows
      // persistTab
      enableColumnFilterModes //enable changing filter mode for all columns unless explicitly disabled in a column def
      initialState={{
        density: "compact",
        // showColumnFilters: true,
        pagination: { pageSize: 15, pageIndex: 0 },
        density: "compact",
      }} //show filters by default
      // filterFns={{
      //   customFilterFn: (row, id, filterValue) => {
      //     console.log(row)
      //     return row.getValue(id) === filterValue;
      //   },
      // }}
      muiTablePaginationProps={
        {
          // rowsPerPageOptions: [10, 20],
          // showFirstButton: false,
          // showLastButton: false,
        }
      }
    />
  );
};

export default HeatmapTable;
