import React, { useEffect, useCallback, useMemo } from 'react';
import '../style.css'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Trash,PencilSquare } from 'react-bootstrap-icons';
import Table from 'react-bootstrap/Table';
import { deleteDivision, getDivision } from '../../../actions/userAdmin/actionCreators.js';
import Loader from "../../loader/Loader.component.jsx";
import DeleteDialog from '../DeleteDialog.jsx';


const Divisions = React.memo(function Divisions() {

  const dispatch = useDispatch();
  const navigate = useHistory();

  const divisionData = useSelector((state) => state.userAdmin.division);
  const isLoader = useSelector((state) => state.userAdmin.divisionloading);
  const currentUser = useSelector((state) => state.projects.currentUser);
  const selectedOrg = useSelector((state) => state.userAdmin.org_id);

  const [open, setOpen] = React.useState(false);
  const [selectedID, setselectedID] = React.useState("");

  const divdata = useMemo(() => ({
    orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" ? currentUser.org_id : selectedOrg
  }), [currentUser, selectedOrg]);

  const handleClickOpen = useCallback((id) => {
    setOpen(true);
    setselectedID(id);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setselectedID("");
  }, []);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    dispatch(deleteDivision(selectedID, divdata))
      .then(() => {
        dispatch(getDivision(divdata));
      })
      .catch((error) => {
        console.error('Error updating user:', error);
      });
  }, [dispatch, selectedID, divdata]);

  useEffect(() => {
    dispatch(getDivision(divdata));
  }, [dispatch, divdata]);

  const handleAddNavigate = useCallback(() => {
    navigate.push('/admin/users/add-division');
  }, [navigate]);

  const handleUpdateNavigate = useCallback((id) => {
    navigate.push({
      pathname: '/admin/users/update-division',
      state: {
        division: id,
      },
    })
  }, [navigate]);

  return (
    <>
      {isLoader ? (<div className="loader flex-center">
        <Loader />
      </div>) : (
        <>
          {currentUser?.roles !== "division admin" && (
            <div className="division-table-container">
              <div className="header">
                <div className="header-label">Divisions</div>
                <div className="header-section">
                  <button className="add-btn div-btn" variant="primary" onClick={handleAddNavigate}>
                    Add division
                  </button>
                </div>
              </div>
              <div className="table-responsive-xl division-list-container">
                <Table size="xl" responsive bordered>
                  <thead style={{background:'#0a1352',color:'white'}}>
                    <tr>
                      <th>Division Name</th>
                      <th style={{ width: "200px", textAlign: "center" }}> Options</th>
                    </tr>
                  </thead>
                  <tbody className="division-table-body">
                    {divisionData.length !== 0 ? (
                      divisionData?.map((item) => (
                        <tr key={item._id} className="userRow">
                          <td onClick={() => handleUpdateNavigate(item)}>{item.division_name}</td>
                          <td style={{ textAlign: "center", display: "flex", justifyContent: "space-evenly" }}>
                            <button onClick={() => handleUpdateNavigate(item)} className='user_edit_btn'><PencilSquare style={{marginRight:"8px"}}/>Edit</button>
                            <button className='user_delete_btn'
                            onClick={() => handleClickOpen(item._id)}
                            > <Trash
                              className="trash-icon"  
                              style={{marginRight:"8px"}}                          
                          />Delete</button>                            
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2" style={{ textAlign: "center" }}>There are no records to display</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          <DeleteDialog open={open}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            contentTitle="Delete the Division?"
            contentText="Please confirm you wish to delete this division."
          />
        </>
      )}
    </>
  );
})

export default Divisions