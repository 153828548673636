import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Modal, Button } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import Swal from 'sweetalert2'

import { addVariable } from '../../actions/projects/experiment'


import Loader from '../loader/Loader.component';


const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2000,

})


class AddVariable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
    }

  }
  async componentDidMount() {
    this.setState({
      show: this.props.showModal
    })
    console.log(this.props)
  }
  createVariable = async (vals) => {


    vals.projectId = this.props.projectId
    vals.dimension = {
      dimensionName: 'untagged',
      files: this.props.projectFiles.files.map(file => file._id)
    }
    vals.experimentId = this.props.experimentId
    console.log(vals)
    await this.props.addVariable(vals)
    this.setState({
      show: false,
    })
    this.props.handleClose(true)
  }

  handleClose = () => {
    this.setState({
      show: false,
    })
    this.props.handleClose()
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Add Variable</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId='exp-name'>
                <Form.Label>Name</Form.Label>
                <Field
                  name="variableName"
                  id='exp-name'
                  component='input'
                  type='text'
                  placeholder='Variable Name'
                >
                </Field>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' variant="primary" onClick={this.props.handleSubmit(this.createVariable)}>
              Create Variable
            </Button>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
          </Button>

          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  projectFiles: state.projects.projectFiles
})
export default connect(mapStateToProps, { addVariable })(reduxForm({ form: 'addVariable' })(AddVariable))