import React, { useState, useEffect, useRef, useReducer } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Form,
  Modal,
  Button,
  ToggleButton,
  Overlay,
  Tooltip,
  CloseButton,
} from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import api from "../../api/projects";
import { useForm } from "react-hook-form";
import {
  listProjects,
  getCategories,
  addProject,
  deleteProject,
} from "../../actions/projects";
import { listProjectFiles } from "../../actions/projects";
import Loader from "../loader/Loader.component";
import "./Overview.css";


const Overview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const _allProjects = useSelector((state) => state.projects.userProjects);
  const currentUser = useSelector((state) => state.projects.currentUser);
  const [allProjects, setAllProjects] = useState([]);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [projectsPending, setProjectsPending] = useState(false);

  //Tooltip
  const [showToolTip, setShowToolTip] = useState(false);
  const toolTipTarget = useRef(null);

  const projectAPI = async() => {
    const results = await api.get("/projects");
    console.log(results);
    dispatch({ type: "LIST_PROJECTS", payload: results.data });
  }

  useEffect( () => {
    projectAPI();
  }, [showDeleteModal]);

  useEffect(() => {
    setAllProjects(_allProjects)
  }, [_allProjects, showDeleteModal])


  const loadProject = ({ _id }) => {
    history.push(`/${_id}/summary`);
  };

  const required = (value) => (value ? undefined : "Required");
  const columns = [
    {
      name: "Project Name",
      selector: "name",
      sortable: true,
      format: ({ name, _id }) => (
        <span className="project-name">
          <Link to={`/${_id}/summary`}>{name}</Link>
        </span>
      ),
    },
    {
      name: "Created By",
      selector: "user",
      sortable: true,
      format: ({ user_nickname }) => <span>{user_nickname}</span>,
    },
    // {
    //   name: "Status",
    //   selector: "status",
    //   sortable: true,
    // },
    {
      name: "Date Created",
      id: 'date',
      selector: row => row.date.created,
      sortable: true,
      format: (row) => (
        <span>
          {moment.unix(row?.date?.created).format("MM/DD/YYYY")},&nbsp; (
          {moment(moment.unix(row?.date?.created)).fromNow()})
        </span>
      ),
    },
    {
      name: "Delete",
      selector: "_id",
      format: (row) => (
        <CloseButton
          key={row._id}
          id={row._id}
          className={row._id}
          onClick={() => {
            setShowDeleteModal(true);
            
            setDeleteProjectId({projectId: row._id, rocket_chat_channel_id: row.rocket_chat_channel_id});
          }}
          style={{
            width: "37px",
            height: "26px",
            fontSize: "22px",
            borderRadius: "4px",
          }}
        />
      ),
      right: true,
    },
  ];

  const onAddProjectSubmit = async (formValues) => {
    // let projectData = {
    //   ...formValues,
    //   _id: user,
    //   date: {
    //     created: moment.unix(),
    //     last_mod: moment.unix(),
    //   },
    //   status: "Active",
    //   collaborators: [],
    //   collaborators_invitation: [],
    // };

    console.log(formValues, moment().unix());
    console.log(formValues.name);

    let projectData = {
      compute_region: formValues.compute_region,
      overview: formValues.overview,
      name: formValues.name,
      category: [],
      user_id: currentUser?.id,
      user_nickname: currentUser?.name,
      user: currentUser?.id,
      date: {
        created: moment().unix(),
        last_mod: moment().unix(),
      },
      status: "Active",
      collaborators: [{
        user_id: currentUser?.id,
        name: currentUser?.name,
        role: "Owner"
      }],
      collaborators_invitation: [],
    };

    console.log(projectData);

    let _p = await dispatch(addProject(projectData));
    console.log(_p.data);
    history.push(`/${_p.data._id}/summary`);

    // const project = await api.post(`/projects`, { projectData });
    // console.log(project);
    // dispatch({ type: "ADD_PROJECT", payload: project.data });
    // this.setState({
    //   showAddProjectModal: false
    // })
    // this.props.reset()
  };


  
  const onDeleteProjectSubmit = async () => {
    setShowDeleteModal(false)
    dispatch(deleteProject(deleteProjectId));
    // const results = await api.get("/projects");
    // console.log(results);
    // dispatch({ type: "LIST_PROJECTS", payload: results.data });
    
  };

  return (
    <>
      <div className="content">
        <div className="boxed_section">
          <div className="boxed_header">
            <h3>
              Projects
            </h3>
            <Link
                to="#"
                className="button interface"
                onClick={() => setShowAddProjectModal(true)}
              >
                Add
              </Link>
            {/* <form className="advanced_search"> */}
            {/* <input
                type="text"
                placeholder="Search"
                value={this.state.searchFilter}
                onChange={this.filterProjects}
              /> */}
            {/* <button type="button">Search</button>
            </form> */}
          </div>
          {allProjects ? (
            <DataTable
              columns={columns}
              data={allProjects}
              // defaultSortAsc={false}
              // defaultSortFieldId="date"
              pagination
              highlightOnHover
              pointerOnHover
              progressPending={projectsPending}
              onRowClicked={loadProject}
              progressComponent={<Loader />}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      <Modal
        show={showAddProjectModal}
        onHide={() => setShowAddProjectModal(false)}
        size="sm"
      >
        <Modal.Header>
          <h4>Add Project</h4>
          <button className="close-x" onClick={() => {
            setShowAddProjectModal(false)
          }}>X</button>
        </Modal.Header>
        <form onSubmit={handleSubmit(onAddProjectSubmit)}>
          <Modal.Body>
            <label>
              Project Name
              <input
                className="form-control"
                name="name"
                id="name"
                type="text"
                {...register("name", { required: true, maxLength: 80 })}
              />
            </label>
            {/* <label>
              Category
              <select
                name="category"
                {...register("category", { required: false })}
              >
                <option value="c1">c1</option>
                <option value="c2">c2</option>
              </select>
            </label> */}
            <label>
              Compute Region
              <select
                name="compute_region"
                {...register("compute_region", { required: true })}
                defaultValue="Multi-Regional"
              >
                <option value="multi-region">Multi-Regional</option>
                <option value="asia-east1">Taiwan</option>
                <option value="asia-northeast1">Tokyo</option>
                <option value="asia-south1">Mumbai</option>
                <option value="asia-southeast1">Singapore</option>
                <option value="australia-southeast1">Sydney</option>
                <option value="europe-west1">Belgium</option>
                <option value="europe-west2">London</option>
                <option value="europe-west3">Frankfurt</option>
                <option value="europe-west-4">Netherlands</option>
                <option value="northamerica-northeast1">Montréal</option>
                <option value="southamerica-east1">São Paulo</option>
                <option value="us-central1">Iowa</option>
                <option value="us-east4">Northern Virginia</option>
                <option value="us-west1">Oregon</option>
              </select>
            </label>
            {/* <label style={{
              display: "flex"
            }}>
              Preemptible Compute
              <Form.Check
                type="switch"
                id="custom-switch"
                // label="Preemptible Compute"
                {...register("overview", { required: false })}
              />
              
            </label> */}
            <label onMouseLeave={() => setShowToolTip(false)}>
              Preemptible Compute{" "}
              <span
                ref={toolTipTarget}
                onMouseEnter={() => setShowToolTip(true)}
              >
                (i)
              </span>
              <Overlay
                target={toolTipTarget.current}
                show={showToolTip}
                placement="right"
              >
                {(props) => (
                  <Tooltip
                    id="button-tooltip-2"
                    // className="tooltip-main"
                    {...props}
                  >
                    <br />
                    Preemptible VM instances are available at much lower price—a
                    60-91% discount—compared to the price of standard VMs.
                    However, Compute Engine might stop (preempt) these instances
                    if it needs to reclaim the compute capacity for allocation
                    to other VMs. <a
                      href="https://cloud.google.com/compute/docs/instances/preemptible"
                      target="_blank"
                    >
                      Cick here for more info.
                    <br />
                    <br />
                    </a>
                    
                  </Tooltip>
                )}
              </Overlay>
              <select
                name="preemptible"
                {...register("preemptible", { required: true })}
                defaultValue="Enable"
              >
                <option value="true">Enable</option>
                <option value="fasle">Disable</option>
              </select>
            </label>
            <label>
              Billing
              <select
                name="billing"
                {...register("billing", { required: true })}
                defaultValue="Enable"
              >
                <option value="billing-A1">Billing A1</option>
                <option value="billing A2">Billing A2</option>
              </select>
            </label>
            <label>
              Project Description
              <textarea
                className="form-control overview-box"
                name="overview"
                id="overview"
                type="text"
                {...register("overview", { required: false })}
                style={{
                  width: "100%",
                  height: "100px",
                  fontSize: "14px",
                }}
              />
            </label>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              type="submit"
              defaultValue="Login"
              data-wait="Please wait..."
            >
              Create Project
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        size="sm"
      >
        <Modal.Header closeButton>
          <h4>Delete Project</h4>
        </Modal.Header>
        <form >
          <Modal.Body>
            <h5>
              Are you sure you want to delete, all the files and data associated
              with it will be permanenantly lost.
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              type="submit"
              defaultValue="Delete"
              data-wait="Please wait..."
              onClick={() => onDeleteProjectSubmit()}
            >
              Delete Project
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default reduxForm({
  form: "AddProject",
})(Overview);
