import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Table from 'react-bootstrap/Table';
import { useHistory } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { useDispatch, useSelector } from 'react-redux'
import { Trash, PencilSquare } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { TablePagination } from '@mui/material';
import {
  getAllDivisionUsers,
  updateUserAdminIsDisabled,
  setActiveDivision,
  deleteDivisionUser
} from '../../../actions/userAdmin/actionCreators';
import DeleteDialog from '../DeleteDialog.jsx';

const DivisionUserTable = React.memo(function DivisionUserTable() {

  const dispatch = useDispatch();
  const navigate = useHistory();


  const data = useSelector((state) => state.userAdmin.allDivision);
  const divisionData = useSelector((state) => state.userAdmin.division);
  const actiondivisionID = useSelector((state) => state.userAdmin.division_id);
  const selectedOrg = useSelector((state) => state.userAdmin.org_id);
  const currentUser = useSelector((state) => state.projects.currentUser);
  // const selectedOrgDiv = useSelector((state) => state.userAdmin.selectedOrgDiv);

  const [divisionId, setdivisionId] = useState(actiondivisionID !== "" ? actiondivisionID : "");
  const [open, setOpen] = React.useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isSwitchDisabled, setSwitchDisabled] = useState(false);

  const userData = useMemo(() => {
    return data.filter(
      (item) => item.roles !== "internal admin" && item.roles !== "company admin"
    );
  }, [data]);

  const divisionList = useMemo(() => {
    let result = divisionData.filter(item => {
      if (currentUser.roles === "internal admin" || currentUser.roles === "company admin") {
        return item;
      } else {
        return currentUser?.divisions.some(item2 => item2.divisionId === item._id);
      }
    });
    return result;
  }, [divisionData, currentUser]);

  useEffect(() => {
    if (actiondivisionID !== "") {
      setdivisionId(actiondivisionID);
      let formData = {
        orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" ? currentUser.org_id : selectedOrg,
        divisionId: actiondivisionID
      }
      dispatch(getAllDivisionUsers(formData));
    } else {
      setdivisionId("");
    }
  }, [currentUser.roles, dispatch, actiondivisionID, currentUser.org_id, selectedOrg])


  const navigateto = useCallback(() => {
    navigate.push('/admin/users/add-division-user')
  }, [navigate]);

  const handlechange = (e) => {
    if (e.target.value !== "") {
      const { value } = e.target;
      setdivisionId(value);
      // let formData = {
      //   orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" ? currentUser.org_id : selectedOrg,
      //   divisionId: value
      // }
      dispatch(setActiveDivision(value));
      // dispatch(getAllDivisionUsers(formData));
    } else {
      const { value } = e.target;
      setdivisionId(value);
      let formData = {
        orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" ? currentUser.org_id : selectedOrg,
        divisionId: value
      }
      dispatch(getAllDivisionUsers(formData));
    }

  }

  const handleClickOpen = (id) => {
    setOpen(true);
    setSelectedID(id);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedID("");
  }, []);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    let formData = {
      orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" ? currentUser.org_id : selectedOrg,
      divisionId: actiondivisionID
    };
    dispatch(deleteDivisionUser(selectedID, formData));
  }, [dispatch, currentUser, selectedOrg, actiondivisionID, selectedID]);

  const updateUser = useCallback((id) => {
    setTimeout(() => {
      navigate.push({
        pathname: '/admin/users/update-division-user',
        state: {
          userData: id,
        },
      });
    }, 700);
  }, [navigate]);

  const handleUpdateUser = (role, value) => {
    if (role === "division admin" && currentUser.roles === "division admin") {
      toast.error("User dont have previlage");
    } else {
      updateUser(value);
    }
  }

  const handleAccessSwitch = useCallback((id, disabled) => {
    if (!isSwitchDisabled) {
      setSwitchDisabled(true);
      dispatch(updateUserAdminIsDisabled(id, !disabled))
        .then(() => {
          let formData = {
            orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" ? currentUser.org_id : selectedOrg,
            divisionId: actiondivisionID
          }
          dispatch(getAllDivisionUsers(formData));
          setSwitchDisabled(false);
        })
        .catch((error) => {
          setSwitchDisabled(false);
          console.error('Error updating user access:', error);
        });
    }
  }, [dispatch, isSwitchDisabled, actiondivisionID, currentUser.roles, currentUser.org_id, selectedOrg]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const toChangeFirstLetterCapital = (role) => {
    return role?.charAt(0).toUpperCase() + role?.slice(1);
  }
  
  return (
    <div className="division-user-table-container">
      <div className="add">
        <div className="text">Division Members</div>
        <div className="admin-division-container">
          <div className="divdrop" style={{ paddingLeft: "30px", margin: "0px" }}>
            <div className="division-container" style={{ width: "280px" }}>
              <label htmlFor="divId">Division</label>
              <select
                name="divisionId"
                value={divisionId}
                onChange={handlechange}
              >
                <option value="">Please select your division name</option>
                {divisionList.map((item) => (
                  <option value={item._id} key={item._id}>
                    {item.division_name}
                  </option>
                ))}
              </select>
            </div>
            <div className='adduserbutton'>
              <button className="add-btn div-btn" variant="primary" onClick={navigateto}>
                Add Division Users
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive-md division-innerTable-container">
        <Table
          responsive
          className="admin-user-table"
          bordered
        >
          <thead style={{ background: '#0a1352', color: 'white' }}>
            <tr>
              <th>User Name</th>
              <th>Nick Name</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th> Email Address</th>
              <th> Role</th>
              <th> Access</th>
              <th style={{ width: "180px", textAlign: "center" }}>Options</th>
            </tr>
          </thead>
          <tbody>
            {userData?.length !== 0 &&
              (rowsPerPage > 0
                ? userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : userData
              ).map((item, i) => (
                <tr key={i} className="userRow">
                  <td onClick={() => handleUpdateUser(item.roles, item)}>{item?.username}</td>
                  <td onClick={() => handleUpdateUser(item.roles, item)}>{item?.nickname}</td>
                  <td onClick={() => handleUpdateUser(item.roles, item)}>{item?.first_name}</td>
                  <td onClick={() => handleUpdateUser(item.roles, item)}>{item?.last_name}</td>
                  <td onClick={() => handleUpdateUser(item.roles, item)}>{item?.email}</td>
                  <td onClick={() => handleUpdateUser(item.roles, item)}>{toChangeFirstLetterCapital(item?.roles)}</td>
                  <td>
                    <BootstrapSwitchButton
                      data-onstyle="outline-primary"
                      data-offstyle="outline-secondary"
                      checked={!item.is_disabled}
                      onChange={() =>
                        handleAccessSwitch(item?._id, item?.is_disabled)
                      }
                      disabled={isSwitchDisabled}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <button disabled={item.roles === "division admin" && true} onClick={() => updateUser(item)} className="user_edit_btn" ><PencilSquare style={{ marginRight: "8px" }} />Edit</button>
                      <button
                        className='no-hover user_delete_btn'
                        style={{ backgroundColor: 'transparent' }}
                        disabled={item.roles === "division admin" && true}
                        onClick={() => handleClickOpen(item._id)}>
                        <Trash
                          style={{ marginRight: "8px" }}
                          className="trash-icon"
                        />Delete
                      </button>
                    </div>

                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {
          userData?.length > 10 ?
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> : null
        }
        {
          userData?.length === 0 ?
            <div className='flex-center' style={{ padding: "24px" }}>There are no records to display</div> : null
        }

      </div>
      <DeleteDialog open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        contentTitle="Delete the division user?"
        contentText="Please confirm you wish to delete this division user."
      />
    </div>
  )
})

export default DivisionUserTable

