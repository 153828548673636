import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Trash, PencilSquare } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../style.css';
import Table from 'react-bootstrap/Table';
import { getBillingAccount, deleteBillingAccount, getSingleBillingAccount, getDivision, getBillingDivisionUser } from "../../../actions/userAdmin/actionCreators"
import Loader from "../../loader/Loader.component";
import DeleteDialog from '../DeleteDialog.jsx';
import {
  Collapse,
  Box,

  List,
  ListItem,
  ListItemText
} from '@mui/material';


function BillingAccount() {

  const dispatch = useDispatch();
  const navigate = useHistory();

  const billingData = useSelector(state => state.userAdmin.billing);
  const currentUser = useSelector(state => state.projects.currentUser);
  const selectedOrg = useSelector(state => state.userAdmin.org_id);
  const isLoader = useSelector(state => state.userAdmin.billingaccountloading);
  const divisionData = useSelector(state => state.userAdmin.division);
  const BillingDivision = useSelector((state) => state.userAdmin.billing_account_division);
  const [open, setOpen] = React.useState(false);
  const [selectedID, setSelectedID] = useState("");

  const navigateToAddBilling = useCallback(() => {
    navigate.push('/admin/users/add-billing');
  }, [navigate]);


  const filterDivisions = useCallback(() => {
    let result = divisionData.filter(item => {
      if (currentUser.roles === "internal admin" || currentUser.roles === "company admin") {
        return item;
      } else {
        return currentUser?.divisions.some(item2 => item2.divisionId === item._id);
      }
    });
    return result;
  }, [divisionData, currentUser]);


  useEffect(() => {
    dispatch(getBillingAccount({ orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" ? currentUser.org_id : selectedOrg }));
    dispatch(getDivision({ orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" ? currentUser.org_id : selectedOrg }));
  }, [currentUser, selectedOrg, dispatch]);

  const divisions = useMemo(() => filterDivisions(), [filterDivisions]);

  const unique = useMemo(() => {
    const divIDs = divisions.map(item => item._id);
    const billIDs = billingData.flatMap(item =>
      item?.divisions?.filter(item1 => divIDs.includes(item1.divisionId)).map(() => item)
    );
    const uniqueIds = [];
    return billIDs.reduce((result, element) => {
      if (!uniqueIds.includes(element._id)) {
        uniqueIds.push(element._id);
        result.push(element);
      }
      return result;
    }, []);
  }, [divisions, billingData]);

  const handleUpdateNavigation = useCallback((id) => {
    dispatch(getSingleBillingAccount(id))
    setTimeout(() => {
      navigate.push({ pathname: '/admin/users/update-billing' });
    }, 700);
  }, [dispatch, navigate]);

  const handleClickOpen = useCallback((id) => {
    setOpen(true);
    setSelectedID(id);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedID("");
  }, []);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    dispatch(deleteBillingAccount(selectedID, { orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" ? currentUser.org_id : selectedOrg }));
  }, [dispatch, selectedID, currentUser, selectedOrg]);

  const [openRow, setOpenRow] = useState(null);

  const handleRowClick = (rowId) => {
    setOpenRow(openRow === rowId ? null : rowId);
    dispatch(getBillingDivisionUser([rowId]));
  };

  return (
    <div>
      <div className="billing-table-container">
        <div className="header">
          <div className="header-label">Billing Account</div>
          <div className="header-section">
            <button className="add-btn div-btn" variant="primary" onClick={navigateToAddBilling}>
              Add Billing Account
            </button>
          </div>
        </div>
        <div className="table-responsive-xl billing-user-table-container">
          {(isLoader) ? <Loader /> : (<>
            <Table size="xl" responsive bordered>
              <thead style={{ background: '#0a1352', color: 'white' }}>
                <tr>
                  <th>Billing Account Name</th>
                  <th style={{ width: "200px", textAlign: "center" }}> Options</th>
                </tr>
              </thead>
              <tbody className="division-table-body">
                {unique?.length !== 0 && unique.map((item) => (
                  <React.Fragment key={item._id}>
                    <tr className="userRow">
                      <td onClick={() => handleRowClick(item._id)} >{item.billing_account_name}</td>
                      <td style={{ textAlign: "center", display: "flex", justifyContent: "space-evenly" }}>
                        <button onClick={() => handleUpdateNavigation(item._id)} className='user_edit_btn'><PencilSquare style={{ marginRight: "8px" }} />Edit</button>
                        <button onClick={() => handleClickOpen(item._id)} className='user_delete_btn'><Trash style={{ marginRight: "8px" }} />Delete</button>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                        <Collapse in={openRow === item._id} timeout="auto" unmountOnExit>
                          <Box margin={1}>
                            {/* Additional content goes here */}
                            <div className='billing_division_name'>
                              Divisions
                            </div>
                            <List>
                              {BillingDivision.divisions && BillingDivision.divisions.map((item) => (
                                <ListItem key={item._id} divider>
                                  <ListItemText className='division_list_name' primary={item.division_name} />
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        </Collapse>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
            {unique?.length === 0 ?
              <div className='flex-center' style={{ padding: "24px" }}>There are no records to display</div> : null
            }</>
          )}
        </div>
      </div>
      <DeleteDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        contentTitle="Delete the billing account?"
        contentText="Please confirm you wish to delete this billing account."
      />
    </div>
  )
}

export default BillingAccount;
