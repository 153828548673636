import React, { useCallback } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Trash,PencilSquare } from 'react-bootstrap-icons';
import EditUser from './EditUser';

const UserData = (props) => {

    const { data,
        handleAccessswitch,
        resetMFA,
        handleView,
        handleClickOpen
    } = props;

    const [editmodalShow, setEditmodalShow] = React.useState(false);
    const [editData, setEditData] = React.useState({});

    const handleEditModalOpen = useCallback((value) => {
        setEditmodalShow(true);
        setEditData(value);
    }, []);

    return (
        <>
            <tr key={data?._id} className='org_row'>
                <td  onClick={() => handleView(data._id)}>{data?.name}</td>
                <td  onClick={() => handleView(data._id)}>{data?.billing_email}</td>
                <td  onClick={() => handleView(data._id)}>{data?.org_address}</td>
                <td  onClick={() => handleView(data._id)}>{data?.users[0]?.first_name}</td>
                <td  onClick={() => handleView(data._id)}>{data?.users[0]?.last_name}</td>
                <td  onClick={() => handleView(data._id)}>{data?.users[0]?.email}</td>               
                <td><button className='mfaresetbtn'
                    onClick={() => resetMFA(data.users[0]._id, false)}>Reset</button></td>
                <td>
                    <BootstrapSwitchButton
                        data-onstyle="outline-primary"
                        data-offstyle="outline-secondary"
                        onlabel="Yes"
                        offlabel="No"
                        checked={data.is_disabled === true ? false : true}
                        onChange={() =>
                            handleAccessswitch(data._id, data.is_disabled)
                        }
                    />
                </td>
                <td>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {/* <button style={{ marginLeft: "3px" }} onClick={}>View</button> */}
                        <button className='org_edit_btn' onClick={() => handleEditModalOpen(data)}><PencilSquare style={{marginRight:"8px"}}/>Edit</button>
                        {editmodalShow === true ? (
                            <>
                                <EditUser
                                    show={editmodalShow}
                                    onHide={() => setEditmodalShow(false)}
                                    editdata={editData}
                                />
                            </>
                        ) : (
                            ''
                        )}
                        <button className='org_delete_btn'onClick={() => handleClickOpen(data._id)}> <Trash
                            className="trash-icon"  
                            style={{marginRight:"8px"}}                          
                        />Delete</button>
                       
                    </div>
                </td>
            </tr>
        </>
    )
}

export default UserData