import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { Link } from 'react-router-dom';

import navIcon from '../../assets/navicon.svg'
class Nav extends Component {
  state = {
    activeIndex: 1,
    responsive: false,
  }
  componentDidMount() {
    this.setState({
      activeIndex: this.props.active || 1
    })
  }
  handleClick = index => {
    this.setState({
      activeIndex: index
    })
  }

  navFunction = () => {
    this.setState({
      responsive: !this.state.responsive
    })
  }
  render() {
    const { match: { params }, project_name } = this.props
    const navItems = [
      { title: 'Overview', to: '/' },
      { title: 'Project Summary', to: `/${params.id}/summary` },
      { title: 'Files', to: `/${params.id}/files` },
      // { title: 'Experimental Design', to: `/${params.id}/tag` },
      { title: 'Analysis', to: `/${params.id}/analysis` },
      { title: 'Output', to: `/${params.id}/output` },
    ]

    return (
      <nav>
        <ul className={(this.state.responsive ? 'nav-menu responsive' : 'nav-menu')}>
          {navItems.map(({ title, to }, index) => (
            <li className={this.state.activeIndex === index ? 'selected' : null} key={index}>
              <Link to={to} onClick={() => this.handleClick(index)}>{title}</Link>
            </li>

          ))}
        {/* <div>{project_name}</div> */}

        </ul>
        <div id="toggle-bar" onClick={this.navFunction}>
          <Link className="navicon" to="#">
            <img src={navIcon} width="30" height="30" alt="navigation" />
          </Link>
        </div>
      </nav>
    )
  }
}

export default withRouter(Nav);