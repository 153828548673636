import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  adduserAdminUsers,
  getDivision,
} from "../../../actions/userAdmin/actionCreators";
import { useHistory } from "react-router";
import closeCircle from '../../../assets/Circle-X-close.png';
import uparrow from "../../../assets/Chevron-Up.png";
import downarrow from "../../../assets/Chevron-Down.png";

const OutsideAlerter = ({ children, setOpen }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  return <div ref={wrapperRef}>{children}</div>;
};

function AddDivisionUser() {
  const history = useHistory();
  const dispatch = useDispatch();

  const divisionData = useSelector((state) => state.userAdmin.division);
  const currentUser = useSelector((state) => state.projects.currentUser);
  const selectedOrg = useSelector((state) => state.userAdmin.org_id);
  const [divisions, setDivisions] = useState([]);
  const [open, setOpen] = useState(false);
  const [switchDisabled, setSwitchDisabled] = useState(false);
  const [formValues, setFormValues] = useState({
    username: "",
    email: "",
    nickname: "",
    roles: "",
    created_by: currentUser.id,
    updated_by: currentUser.id,
    first_name: "",
    last_name: ""
  });
  const errors = {};
  const [formErrors, setFormErrors] = useState({});

  const orgData = useMemo(() => ({
    orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin"
      ? currentUser.org_id
      : selectedOrg,
  }), [currentUser.roles, currentUser.org_id, selectedOrg]);

  const divisionList = useMemo(() => {
    let result = divisionData.filter((item) => {
      if (
        currentUser.roles === "internal admin" ||
        currentUser.roles === "company admin"
      ) {
        return item;
      } else {
        return currentUser?.divisions.some(
          (item2) => item2.divisionId === item._id
        );
      }
    });
    return result.map((item) => ({
      value: item._id,
      label: item.division_name,
    }));
  }, [divisionData, currentUser.roles, currentUser.divisions]);

  useEffect(() => {
    dispatch(getDivision(orgData));
  }, [dispatch, orgData]);

  const handlechange = useCallback((e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const ref = /^[a-zA-Z0-9\s]+$/;
    const eml = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const formData = {
      ...formValues,
      username: formValues.email,
      nickname: `${formValues.first_name} ${formValues.last_name}`,
      is_disabled: switchDisabled,
      date_created: Math.floor(Date.now() / 1000),
      updated_at: Math.floor(Date.now() / 1000),
      organisation_id: orgData.orgId,
      // divisions: [{ divisionId }],
      divisions: divisions.map(div => ({ divisionId: div.value })),
    }

    if (!formData.email.trim()) {
      errors.email = "Field is empty";
    } else if (!eml.test(formData.email)) {
      errors.email = "Enter valid Email";
    }
    if (!formData.first_name.trim()) {
      errors.first_name = "Field is empty";
    } else if (!ref.test(formData.first_name)) {
      errors.first_name = "Special characters not allowed";
    }

    if (!formData.last_name.trim()) {
      errors.last_name = "Field is empty";
    } else if (!ref.test(formData.last_name)) {
      errors.last_name = "Special characters not allowed";
    }
    if (!formData.roles) {
      errors.roles = "Field is empty";
    }
    if (divisions.map(div => ({ divisionId: div.value })).length === 0) {
      errors.division_id = "Field is empty";
    }
    if (!formData.organisation_id) {
      errors.organisation_id = "Field is empty";
    }

    setFormErrors(errors);

    if (
      formData &&
      formData.first_name !== "" &&
      formData.last_name !== "" &&
      formData.email !== "" &&
      formData.roles !== "" &&
      formData.organisation_id !== "" &&
      Object.keys(errors).length === 0
    ) {
      dispatch(adduserAdminUsers(formData))
        .then(() => {
          history.push("/admin/users/divisions");
          setFormValues({
            username: "",
            email: "",
            nickname: "",
            roles: "",
            created_by: currentUser.id,
            first_name: "",
            last_name: "",
            updated_by: currentUser.id,
          });
        })
        .catch((error) => {
          console.error('Error adding user:', error);
        });
    }
  };

  const handleBack = useCallback((e) => {
    e.preventDefault();
    history.goBack();
  }, [history]);

  const closeDropDown = useCallback(() => {
    setDivisions([]);
    setOpen(false);
  }, []);

  const handleCheckboxChange = useCallback((value) => {
    setDivisions([{ value, label: value }]);
  }, []);

  return (
    <div className="mod">
      <Modal.Body className="">
        <div className="pull"> </div>
        <h4 className="text-center">ADD DIVISION USER</h4>
        <form>
          <div className="row m-3">
            <div className="col ">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={handlechange}
                name="first_name"
                value={formValues.first_name}
              />
              <span className="text-danger"> {formErrors.first_name} </span>
            </div>
            <div className="col">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                onChange={handlechange}
                name="last_name"
                value={formValues.last_name}
              />
              <span className="text-danger">{formErrors.last_name} </span>
            </div>
          </div>
          <div className="row m-3">
            <div className="col">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                onChange={handlechange}
                name="email"
                value={formValues.email}
              />
              <span className="text-danger">{formErrors.email} </span>
            </div>
            <div className="col add-user-select">
              <label htmlFor="roles">Role</label>
              <select
                name="roles"
                value={formValues.roles}
                onChange={handlechange}
              >
                <option value="">Please select role</option>
                <option value="member">Member</option>
                <option value="view only">View only</option>
              </select>
              <span className="text-danger"> {formErrors.roles} </span>
            </div>
          </div>
          <div className="row m-3 d-flex ">
            <div className="col-6 add-user-select">
              <div className="multi-select-dropdown">
                <label htmlFor="division_id">Division Name</label>
                <OutsideAlerter setOpen={setOpen}>
                  <div className="multi-select-dropdown_wrapper">
                    <div className='multi-select-dropdown-input' onClick={() => setOpen((prev) => !prev)}>
                      {divisions.length ? `${divisions.length} Division selected` : "Division Name"}
                    </div>
                    <div className='multi-select-dropdown-direction'>
                      <img src={closeCircle} height={18} onClick={closeDropDown} alt="closeIcon" />
                      {open ? <img src={downarrow} height={18} onClick={() => setOpen(false)} alt="downArrowIcon" /> : <img src={uparrow} height={18} onClick={() => setOpen(true)} alt="upArrowIcon" />}
                    </div>
                  </div>
                  <div className="multi-select-dropdown_options_wrapper" style={{ display: open ? "block" : "none" }}>
                    <ul className="multi-select-dropdown_options">
                      {divisionList.map(option => {
                        return (
                          <li className="multi-select-dropdown_option" key={option.value} onClick={() => handleCheckboxChange(option.value)}>
                            <input
                              type="radio"
                              name="options"
                              value={option.value}
                              checked={divisions.find((val) => val.value === option.value)}
                              onChange={() => { }}
                              className="c-multi-select-dropdown_option-checkbox"></input>
                            <span>{option.label}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </OutsideAlerter>
                <span className="text-danger" style={{ fontSize: "14px", fontWeight: "400" }}>{formErrors.division_id} </span>
              </div>
            </div>
            <div className="col-6 justify-content-end">
              <p className="edit-access">Access</p>
              <BootstrapSwitchButton
                data-onstyle="outline-primary"
                data-offstyle="outline-secondary"
                checked={switchDisabled}
                onChange={() => {
                  setSwitchDisabled(switchDisabled);
                }}
              />
            </div>
          </div>

          <div className="row m-3">
            <div style={{ marginTop: "10px" }}>
              <p style={{ color: "red" }}>
                * NOTE:- A division must be formed before creating a division user
              </p>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={handleSubmit}
              className="add-btn btn btn-primary  m-3"
            >
              Add User
            </button>
            <button
              onClick={handleBack}
              className="add-btn btn btn-primary  m-3"
            >
              Back
            </button>
          </div>
        </form>
      </Modal.Body>
    </div>
  );
}

export default AddDivisionUser;
