import React, { useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaRegWindowClose } from 'react-icons/fa';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import "./internaladmin.css"
import { useSelector, useDispatch } from 'react-redux'
import { addAdminUsers, getAdminUsers } from '../../actions/InternalAdmin/actionCreators'

function AddUser(props) {

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.projects.currentUser);

  const [formValues, setFormValues] = useState({
    name: '',
    billing_email: '',
    org_address: '',
    first_name: '',
    last_name: '',
    email: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [sameDomainEnable, setSameDomainEnable] = useState(false);

  const handlechange = useCallback((e) => {
    const { name, value } = e.target;
    setFormValues(prevState => ({ ...prevState, [name]: value }));
  }, []);

  const handleAccessswitch = useCallback((event)=> {
    setSameDomainEnable(event)
  },[]);
  
  const validateForm = () => {
    const errors = {};
    const ref = /^[a-zA-Z]+$/;
    const eml = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formValues.name) {
      errors.name = 'Field is empty';
    }
    // Add other validation rules here
    if (!formValues.billing_email) {
      errors.billing_email = 'Field is empty'
    } else if (!eml.test(formValues.billing_email)) {
      errors.billing_email = 'Enter valid Email'
    }
    if (!formValues.org_address) {
      errors.org_address = 'Field is empty'
    }
    if (!formValues.email) {
      errors.email = 'Field is empty'
    } else if (!eml.test(formValues.email)) {
      errors.email = 'Enter valid Email'
    }
    if (!formValues.first_name) {
      errors.first_name = 'Field is empty'
    } else if (!ref.test(formValues.first_name)) {
      errors.first_name = 'Alphabets only'
    }
    if (!formValues.last_name) {
      errors.last_name = 'Field is empty'
    } else if (!ref.test(formValues.last_name)) {
      errors.last_name = 'Alphabets only'
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = {
        ...formValues,
        enable_domain: sameDomainEnable,
        created_at: Math.floor(Date.now() / 1000),
        updated_at: Math.floor(Date.now() / 1000),
        created_by: currentUser.id,
        updated_by: currentUser.id
      };
      dispatch(addAdminUsers(formData))
        .then(() => {
          dispatch(getAdminUsers());
          setFormValues({
            name: '',
            billing_email: '',
            org_address: '',
            first_name: '',
            last_name: '',
            email: ''
          });
          props.onHide(false);
        })
        .catch((error) => {
          console.error('Error adding user:', error);
        });
    }
  };

  return (
    <Modal
      className="org_modal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal_close">
          {' '}
          <FaRegWindowClose className="closeSVG" onClick={props.onHide} />
        </div>
        <h4 className="text-center">Add User</h4>
        <form>
          <div className="row m-3">
            <div className="col ">
              <label htmlFor="name">Organization</label>
              <input
                type="text"
                className="form-control"
                placeholder="Organization"
                onChange={handlechange}
                name="name"
                value={formValues.name}
              />
              <span className="text-danger"> {formErrors.name} </span>
            </div>
            <div className="col">
              <label htmlFor="billing_email">Org Billing Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Org Billing Email"
                onChange={handlechange}
                name="billing_email"
                value={formValues.billing_email}
              />
              <span className="text-danger">{formErrors.billing_email} </span>
            </div>
          </div>
          <div className="row m-3">
            <div className="col">
              <label htmlFor="org_address">Org Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Org Address"
                onChange={handlechange}
                name="org_address"
                value={formValues.org_address}
              />
              <span className="text-danger"> {formErrors.org_address} </span>
            </div>
            <div className="col">
              <label htmlFor="email">Administrator Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Administrator Email"
                onChange={handlechange}
                name="email"
                value={formValues.email}
              />
              <span className="text-danger"> {formErrors.email} </span>
            </div>
          </div>
          <div className="row m-3">
            <div className="col">
              <label htmlFor="first_name">Administrator First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Administrator First Name"
                onChange={handlechange}
                name="first_name"
                value={formValues.first_name}
              />
              <span className="text-danger"> {formErrors.first_name} </span>
            </div>
            <div className="col">
              <label htmlFor="last_name">Administrator Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Administrator Last Name"
                onChange={handlechange}
                name="last_name"
                value={formValues.last_name}
              />
              <span className="text-danger"> {formErrors.last_name} </span>
            </div>
          </div>
          <div className="row m-3">
            <div className="col">
            <label htmlFor="non-institutional">Allow users to signup with org domain</label>
             
              <BootstrapSwitchButton
                data-onstyle="outline-primary"
                data-offstyle="outline-secondary"
                onlabel="Yes"
                offlabel="No"
                checked={sameDomainEnable}
              onChange={handleAccessswitch}
              />
            </div>
          </div>
          <div className="text-center">
            <button onClick={handleSubmit} className="btn btn-primary ia-btn m-3">
              Add User
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
export default AddUser
