import React from 'react';
import { Button as MaterialButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const DeleteDialog = ({ open, handleClose, handleConfirm, contentTitle, contentText }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{contentTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MaterialButton onClick={handleConfirm} autoFocus>
          Confirm
        </MaterialButton>
        <MaterialButton onClick={handleClose}>Cancel</MaterialButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
