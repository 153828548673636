import React, { useEffect, useMemo, useCallback } from "react";
import "./Billing.css";
import { getBillingAccount, getDivision, getBillbigQuery, getBillbigQuerydetail, resetBillinigData } from "../../../actions/userAdmin/actionCreators"
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker } from 'rsuite/dist/rsuite';
import { startOfMonth, lastDayOfMonth, } from "date-fns";
import moment from "moment/moment";
import { toast } from 'react-toastify';
import Loader from "../../loader/Loader.component";
import TablePagination from '@mui/material/TablePagination';

function Billing() {

  const dispatch = useDispatch();

  const divisionData = useSelector((state) => state.userAdmin.division);
  const currentUser = useSelector((state) => state.projects.currentUser);
  const selectedOrg = useSelector((state) => state.userAdmin.org_id);
  const billingData = useSelector((state) => state.userAdmin.billing);
  const billingCost = useSelector((state) => state.userAdmin.billingCost);
  const UserData = useSelector((state) => state.userAdmin.userAdminusers);
  const billingCostDetail = useSelector((state) => state.userAdmin.billingDetail);
  const isLoader = useSelector((state) => state.userAdmin.billingloading);

  const [divisionId, setdivisionId] = React.useState("");
  const [billingID, setbillingID] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dateRange, setDateRange] = React.useState([startOfMonth(new Date()), lastDayOfMonth(new Date())]);

  const formValues = useMemo(() => {
    return {
      orgId: currentUser.roles === "company admin" || currentUser.roles === "division admin" || currentUser.roles === "member" ? currentUser.org_id : selectedOrg
    };
  }, [currentUser, selectedOrg]);


  const memoizedGetBillingAccount = useCallback(() => {
    dispatch(getBillingAccount(formValues));
  }, [dispatch, formValues]);

  const memoizedGetDivision = useCallback(() => {
    dispatch(getDivision(formValues));
  }, [dispatch, formValues]);

  useEffect(() => {
    memoizedGetBillingAccount();
    memoizedGetDivision();
    return () => {
      dispatch(resetBillinigData());
   }
  }, [memoizedGetBillingAccount, memoizedGetDivision]);


  const Ranges = [];

  const [billingList, setbillingList] = React.useState([]);
  const columns = [
    { id: "date", label: "Date" },
    { id: "resource", label: "Resource", },
    { id: "resource_type", label: "Resource Type", },
    { id: "user_id", label: "User", sortby: true, },
    { id: "cost", label: "Cost", sortby: true, },
  ];

  const handlechange = (e) => {
    const { value } = e.target;
    if (value !== 0) {
      setbillingID("");
      setdivisionId(value);
      let filter = billingData.filter((item) => {
        return item?.divisions.find((item1) => item1.divisionId === value)
      })

      setbillingList(filter)
    } else {
      setdivisionId(value);
    }
  }

  const Billinghandlechange = (e) => {
    const { value } = e.target;
    setbillingID(value);
    let data = {
      id: value,
      date: dateRange
    }

    if (dateRange.length) {
      dispatch(getBillbigQuery(data));
    } else {
      toast.error("Select the date range")
    }
  }

  const divisionList = useMemo(() => {
    let result = divisionData.filter((item) => {
      if (
        currentUser.roles === "internal admin" ||
        currentUser.roles === "company admin"
      ) {
        return item;
      } else {
        return currentUser?.divisions.some(
          (item2) => item2.divisionId === item._id
        );
      }
    });
    return result.map((item) => ({
      _id: item._id,
      division_name: item.division_name,
    }));
  }, [divisionData, currentUser.roles, currentUser.divisions]);

  const memoizedViewCostDetail = useCallback(() => {
    if (divisionId !== "" && dateRange.length !== 0) {
      let data = {
        id: billingID,
        date: dateRange
      }
      dispatch(getBillbigQuerydetail(data));
    } else {
      toast.error("Please select the division and date range")
    }
  }, [dispatch, divisionId, billingID, dateRange]);

  const datechange = (value) => {
    if (value.length) {
      setDateRange(value);
      if (divisionId !== "") {

        let filter = billingData.filter((item) => {
          return item?.divisions.find((item1) => item1.divisionId === divisionId)
        })

        let data = {
          id: filter[0]._id,
          date: value
        }
        dispatch(getBillbigQuery(data));
      } else {
        toast.error("Select the Division")
      }
    } else {
      setDateRange(value);
    }
  };

  const datechangeChange = (value, event) => {

  }

  const datechangeClear = useCallback(() => {
    setDateRange([]);
  }, []);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const getUserName = (value) => {
    const result = UserData.find(item => item._id === value);
    return result?.username || value;
  };

  return (
    <>
      <div className="billing-header-container">
        <div className="division-container" style={{ width: "200px" }}>
          <label htmlFor="divId">Division</label>
          <select
            name="divisionId"
            value={divisionId}
            onChange={handlechange}
          >
            <option value={0}>Select Division name</option>
            {divisionList.map((item) => (
              <option value={item._id} key={item._id}>
                {item.division_name}
              </option>
            ))}
          </select>
        </div>
        <DateRangePicker
          defaultValue={[startOfMonth(new Date()), lastDayOfMonth(new Date())]}
          placeholder="Select Date Range"
          onOk={(value) => datechange(value)}
          onChange={(value, label) => datechangeChange(value, label)}
          onClean={() => datechangeClear()}
          ranges={Ranges}
          style={{ width: 230 }}
        />
        <div className="division-container" style={{ width: "200px" }}>
          <label htmlFor="billing_Account_Id">Billing Account</label>
          <select
            name="billingAccountId"
            value={billingID}
            onChange={Billinghandlechange}
            // className="disabledclass"           
          >
            <option>Select Billing name</option>
            {billingList.map((item) => (
              <option value={item._id} key={item._id}>
                {item.billing_account_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {isLoader ? (<div className="loader flex-center">
        <Loader />
      </div>) : (
        <>
          <div className="table-head-container">
            <div className="table-head-text" >
              <div>Selected Date Range: {moment(dateRange[0]).format("DD-MM-YYYY") + " to " + moment(dateRange[1]).format("DD-MM-YYYY")}</div>
            </div>

          </div>
          <div className="billing-container">
            <div className="card">
              <div className="card-body">

                <div className="input-group mb-3">
                  <input type="text" className="form-control" size="sm" placeholder="Computational charges" aria-label="Recipient's username" aria-describedby="basic-addon2" disabled></input>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">{billingCost.Compute_Engine_Cost ? "$" + billingCost.Compute_Engine_Cost.toFixed(2) : "$0"}</span>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="text" className="form-control col-xs-2" placeholder="Storage charges" aria-label="Recipient's username" aria-describedby="basic-addon2" disabled></input>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">{billingCost.Cloud_storage_Cost ? "$" + billingCost.Cloud_storage_Cost.toFixed(2) : "$0"}</span>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Other Charges" aria-label="Recipient's username" aria-describedby="basic-addon2" disabled></input>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">$0</span>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Total Charges For Period" aria-label="Recipient's username" aria-describedby="basic-addon2" disabled></input>
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">{billingCost.Total_Cost ? "$" + billingCost.Total_Cost.toFixed(2) : "$0"}</span>
                  </div>
                </div>
                <div className='left'>
                  {/* <button className='lg'>ADD</button> */}
                  <button type="submit" className="view-btn" onClick={memoizedViewCostDetail}>View Detailed Report</button>
                </div>
              </div>
            </div>
          </div>

          {(billingCostDetail?.rows?.[0].length !== 0 && billingCostDetail?.rows?.[0] !== undefined) && <>
            <div className="billing-table">
              <Table
                responsive                
                bordered
              >
                <thead  style={{background:'#0a1352',color:'white'}}>
                  <tr>
                    {columns.map((head) => (
                      <th>
                        {head.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {billingCostDetail?.rows?.[0] && (rowsPerPage > 0
                    ? billingCostDetail?.rows?.[0].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : billingCostDetail?.rows?.[0]
                  ).map(item => {
                    return (
                      <tr>
                        {item.labels.map((val) => {
                          const res = val.key === "date" ?
                            <td>{val.value}</td> :
                            val.key === "resource" ?
                              <td>{val.value}</td> :
                              val.key === "resource_type" ?
                                <td>{val.value}</td> :
                                val.key === "user_id" ?
                                  <td>{getUserName(val.value)}</td> :
                                  null
                          return res
                        }
                        )}
                        <td>{item.cost}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {
                billingCostDetail?.rows?.[0].length > 10 ?
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={billingCostDetail?.rows?.[0].length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> : null
              }
              {
                billingCostDetail?.rows?.[0].length === 0 ?
                  <div className='flex-center' style={{ padding: "24px" }}>There are no records to display</div> : null
              }
            </div>
          </>}
        </>
      )}
    </>
  );
}

export default Billing;