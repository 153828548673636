import { GET_EXPIRMENT, ADD_EXPIRMENT, ADD_VARIABLE, ADD_DIMENSION, REMOVE_DIMENSION } from '../actions/types'

const expirmentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EXPIRMENT:
    case ADD_EXPIRMENT:
    case ADD_VARIABLE:

      return { ...state, experiment: action.payload }

    case ADD_DIMENSION:
      if (action.payload.dimension) {
        // const { variable } = action.payload
        // let experiments = state.experiment
        // let exps = experiments.map(experiment => {
        //   if (experiment._id === variable.experimentId) {
        //     experiment.variables = experiment.variables.map(v => {
        //       if (v._id === variable._id) {
        //         v.dimensions = variable.dimensions
        //       }
        //       return v
        //     })
        //   }
        //   return experiment
        // })

        // return { ...state, experiment: exps }
        return { ...state, dimension: action.payload.dimension }

      }
      return { ...state, }

    case REMOVE_DIMENSION:
      return state
    default:
      return state
  }
}

export default expirmentReducer