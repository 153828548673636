import {
  ADD_DIVISION,
  ADD_USERADMIN_USERS,
  GET_DIVISION,
  DELETE_DIVISION,
  GET_SINGLE_DIVISION,
  UPDATE_DIVISION,
  GET_DIVISION_USER_ALL,
  GET_USERADMIN_USERS,
  DELETE_USERADMIN_USERS,
  GET_SINGLE_USERADMIN_USERS,
  UPDATE_SINGLE_USERADMIN_USERS,
  DELETE_DIVISION_USER,
  UPDATE_USERADMIN_IS_DISABLED,
  UPDATE_DIVISION_IS_DISABLED,
  GET_BILLING_ACCOUNT,
  ADD_BILLING_ACCOUNT,
  DELETE_BILLING_ACCOUNT,
  GET_SINGLE_BILLING_ACCOUNT,
  UPDATE_SINGLE_BILLING_ACCOUNT,
  GET_BILLING_ACCOUNT_DIVISION,
  ADD_BILLING_ACCOUNT_DIVISION,
  DELETE_BILLING_ACCOUNT_DIVISION,
  GET_USERADMIN_USERS_LOADING,
  GET_USER_ORGID,
  DELETE_USERADMIN_USERS_REMOVE_FILES,
  DELETE_USERADMIN_USERS_TRANSFER_FILES,
  BILLING_CLERE,
  DIVISION_CLERE,
  GET_USER_SELECTED_TAB,
  SET_USER_SELECTED_DIVISION,
  SET_USER_SELECTED_BILLING,
  GET_BILL_AMOUNT,
  RESET_BILLING_DATA,
  GET_BILL_DETAIL,
  GET_DIVISIONS_LOADING,
  GET_BILLING_LOADING,
  GET_BILLING_ACCOUNT_LOADING
} from '../actions/types'

const initialState = {
  division: [],
  userAdminusers: [],
  allDivision: [],
  billing: [],
  billing_account_division: [],
  singledivision: {},
  singleUserAdminuser: {},
  singleBillingAccount: {},
  loading: false,
  divisionloading:false,
  billingloading:false,
  billingaccountloading:false,
  bills_id: {},
  division_id: "",
  org_id: "",
  selectedOrgDiv: [],
  currentTab: 0,
  activeBillingId: "",
  selectedBillingObject:[],
  billingDetail:null,
  billingCost:{}
}

export const userAdminReducer = (state = initialState, action) => {
  switch (action.type) {

    // admin division
    case GET_DIVISION:
      return {
        ...state,
        division: action.payload,
        divisionloading: false
      }

    case ADD_DIVISION:
      return {
        ...state,
        divisionloading: false
      }

    case GET_SINGLE_DIVISION:
      return {
        ...state,
        singledivision: action.payload,
      }

    case UPDATE_DIVISION:
      return {
        ...state,
        divisionloading: false
      }

    case DELETE_DIVISION:
      return {
        ...state,
        divisionloading: false
      }


    // Division tab 
    case DIVISION_CLERE:
      return {
        ...state,
        allDivision: "",
        division_id: "",
      }
    case GET_DIVISION_USER_ALL:
      return {
        ...state,
        allDivision: action.payload,
        // division_id: action.onchangeId,
      }
    case DELETE_DIVISION_USER:
      return {
        ...state,
      }
    case UPDATE_DIVISION_IS_DISABLED:
      return {
        ...state,
        _id: {
          ...state.adminUsers,
          ...action.payload,
        },
      }
    // user admin users cases
    case GET_USERADMIN_USERS_LOADING:
      return {
        ...state,
        loading: true
      }

      case GET_DIVISIONS_LOADING:
      return {
        ...state,
        divisionloading: true
      } 
      case GET_BILLING_LOADING:
      return {
        ...state,
        billingloading: true
      }
      case GET_BILLING_ACCOUNT_LOADING:
        return {
          ...state,
          billingaccountloading: true
        }
    case GET_USER_ORGID:
      return {
        ...state,
        org_id: action.payload,
        loading: false
      }

    case GET_USER_SELECTED_TAB:
      return {
        ...state,
        currentTab: action.payload,
        loading: false
      }
    case SET_USER_SELECTED_DIVISION:
      return {
        ...state,
        division_id: action.payload,
        loading: false
      }
    case SET_USER_SELECTED_BILLING:
      return {
        ...state,
        activeBillingId: action.payload.id,
        selectedBillingObject:action.payload.selectedObj,
        loading: false
      }

    case GET_USERADMIN_USERS:
      return {
        ...state,
        userAdminusers: action.payload,
        selectedOrgDiv: action.payload ? action.payload[0].divisions : [],
        loading: false
      }

    case GET_SINGLE_USERADMIN_USERS:
      return {
        ...state,
        singleUserAdminuser: action.payload,
        loading: false

      }

    case ADD_USERADMIN_USERS:
      return {
        ...state,
        loading: false
      }

    case UPDATE_SINGLE_USERADMIN_USERS:
      return {
        ...state,
        loading: false
      }

    case DELETE_USERADMIN_USERS:
      return {
        ...state,
        loading: false
      }
    case DELETE_USERADMIN_USERS_REMOVE_FILES:
      return {
        ...state,
        loading: false
      }
    case DELETE_USERADMIN_USERS_TRANSFER_FILES:
      return {
        ...state,
        loading: false
      }
    case UPDATE_USERADMIN_IS_DISABLED:
      return {
        ...state,
        _id: {
          ...state.adminUsers,
          ...action.payload,
        },
        loading: false
      }
    // Billing Account
    case GET_BILLING_ACCOUNT:
      return {
        ...state,
        billing: action.payload,
        billingaccountloading: false
      }
    case ADD_BILLING_ACCOUNT:
      return {
        ...state,
        billingaccountloading: false
      }
    case DELETE_BILLING_ACCOUNT:
      return {
        ...state,
        billingaccountloading: false
      }
    case GET_SINGLE_BILLING_ACCOUNT:
      return {
        ...state,
        singleBillingAccount: action.payload,
      }
    case UPDATE_SINGLE_BILLING_ACCOUNT:
      return {
        ...state,
        loading: false
      }
    case GET_BILLING_ACCOUNT_DIVISION:
      return {
        ...state,
        billing_account_division: action.payload,
        bills_id: action.data,
        loading: false,

      }
    case ADD_BILLING_ACCOUNT_DIVISION:
      return {
        ...state,
        loading: false
      }
    case DELETE_BILLING_ACCOUNT_DIVISION:
      return {
        ...state,
        loading: false
      }
    case BILLING_CLERE:
      return {
        ...state,
        billing_account_division: "",
        bills_id: "",
        loading: false
      }
      case GET_BILL_AMOUNT:
        return {
          ...state,
        billingCost: action.payload,
        billingDetail:[],
        billingloading: false
      }
      case RESET_BILLING_DATA:
        return {
          ...state,
        billingCost: [],
        billingDetail:[],
        billingloading: false
        }
    case GET_BILL_DETAIL:
      return {
        ...state,
        billingDetail: action.payload,
        billingloading: false
      }

    default:
      return {
        ...state,
      }
  }
}
